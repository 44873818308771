import { Edit, SimpleForm, TextInput } from 'react-admin';
import ImageUploader from '../../components/ImageUploader';
import StatusInput from '../../components/StatusInput';

export const CategoryEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="title" />
      <ImageUploader source="imageUrl" label="카테고리 이미지" width={100} />
      <TextInput source="description" multiline fullWidth minRows={3} />
      <StatusInput source="status" defaultValue={'ACTIVE'} />
    </SimpleForm>
  </Edit>
);
