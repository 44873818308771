import CategoryIcon from '@mui/icons-material/Category';
import { QuestionCategoryCreate } from './create';
import { QuestionCategoryEdit } from './edit';
import { QuestionCategoryList } from './list';
import { QuestionCategoryShow } from './show';

const questionCategories = {
  name: 'question-categories',
  icon: CategoryIcon,
  list: QuestionCategoryList,
  show: QuestionCategoryShow,
  edit: QuestionCategoryEdit,
  create: QuestionCategoryCreate,
  options: { label: '질문 카테고리 관리', permission: 'admin' },
};

export default questionCategories;
