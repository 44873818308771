import {
  AutocompleteInput,
  DeleteWithConfirmButton,
  Edit,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import ImageUploader from '../../components/ImageUploader';
import StatusInput from '../../components/StatusInput';
import { formatUser } from '../../utils/format';

const CustomToolbar = (props: any) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle="시리즈를 삭제하시겠습니까?"
      confirmContent="포함된 컨텐츠들의 시리즈 정보가 사라집니다"
    />
  </Toolbar>
);

export const SeriesesEdit = () => (
  <Edit>
    <SimpleForm toolbar={<CustomToolbar />} sx={{ maxWidth: 600 }}>
      <ReferenceInput source="userId" reference="users" label="저자">
        <AutocompleteInput
          fullWidth
          source="q"
          label="회원명 / 이메일"
          optionText={formatUser}
        />
      </ReferenceInput>
      <TextInput fullWidth source="title" label="제목" />
      <TextInput
        fullWidth
        source="description"
        label="설명"
        multiline
        minRows={3}
      />
      <ImageUploader
        width={200}
        height={300}
        source="coverImageUrl"
        label="커버이미지"
      />
      <StatusInput />
    </SimpleForm>
  </Edit>
);
