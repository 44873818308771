import RequestIcon from '@mui/icons-material/RequestPage';
import SettlementRequestList from './list';
import SettlementRequestShow from './show';

const settlementRequests = {
  name: 'settlement-requests',
  icon: RequestIcon,
  list: SettlementRequestList,
  show: SettlementRequestShow,
  options: { label: '정산요청' },
};

export default settlementRequests;
