import { Create, NumberInput, SimpleForm } from 'react-admin';
import PieIconInput from '../../components/PieIconInput';
import PointPackagePromotionInput from '../../components/PointPackagePromotionInput';

export const PointPackageCreate = () => (
  <Create>
    <SimpleForm>
      <PieIconInput />
      <NumberInput source="points" label="파이갯수" />
      <NumberInput source="price" label="가격" />
      <PointPackagePromotionInput source="promotion" label="프로모션명" />
    </SimpleForm>
  </Create>
);
