import { Link, useListContext } from 'react-admin';

const SeriesList = () => {
  const { data } = useListContext();

  return (
    <ol style={{ listStyle: 'circle' }}>
      {data.map((series: any) => {
        return (
          <li key={series.id}>
            <Link
              to={`/serieses/${series.id}/show`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {series.title} (id: {series.id})
            </Link>
          </li>
        );
      })}
    </ol>
  );
};

export default SeriesList;
