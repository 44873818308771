import {
  Datagrid,
  FunctionField,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import PieIcon from '../../components/PieIcon';
import PointPackagePromotionField from '../../components/PointPackagePromotionField';

export const PointPackageList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />

      <FunctionField
        label="아이콘"
        render={(record: any) => <PieIcon src={record.iconUrl} />}
      />
      <NumberField source="points" label="파이갯수" />
      <CurrencyField source="price" label="가격" />
      <NumberField source="discountRate" label="할인율" sortable={false} />
      <PointPackagePromotionField source="promotion" label="프로모션명" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
