import {
  Datagrid,
  List,
  NumberField,
  TextField,
  TextInput,
  WrapperField,
} from 'react-admin';
import CouponTypeField from '../../components/CouponTypeField';
import DateTimeField from '../../components/DateTimeField';
const couponFilter = [<TextInput label="제목" source="q" alwaysOn />];

export const CouponList = () => (
  <List exporter={false} filters={couponFilter}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" label="제목" />
      <CouponTypeField />
      <TextField source="fixedCode" label="고정형_코드" />
      <NumberField source="maxProvideCount" label="고정형_쿠폰 발급 갯수" />
      <DateTimeField source="startAt" label="시작일" />
      <DateTimeField source="expiredAt" label="만료일" />
      <WrapperField label="생성/수정">
        <DateTimeField source="createdAt" />
        <br />
        <DateTimeField source="updatedAt" />
      </WrapperField>
    </Datagrid>
  </List>
);
