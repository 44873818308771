import { Create, SimpleForm, TextInput } from 'react-admin';
import HexColorInput from '../../components/HexColorInput';

export const QuestionCategoryCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" />
      <HexColorInput source="hexColor" label="색상" />
    </SimpleForm>
  </Create>
);
