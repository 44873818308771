type Props = {
  src: string;
  onClick?: () => void;
};

const PieIcon: React.FC<Props> = ({ src, onClick }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        width: 48,
        height: 48,
        background: '#fff',
        border: '1px solid #eee',
        marginRight: 4,
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <img style={{ width: '100%', height: '100%' }} src={src} alt="" />
    </div>
  );
};

export default PieIcon;
