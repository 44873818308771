import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { ContentCreate } from './create';
import { ContentEdit } from './edit';
import { ContentList } from './list';
import { ContentShow } from './show';

const contents = {
  name: 'contents',
  icon: TextSnippetIcon,
  list: ContentList,
  edit: ContentEdit,
  show: ContentShow,
  create: ContentCreate,
  options: { label: '파이퍼 관리' },
};

export default contents;
