import RedeemIcon from '@mui/icons-material/Redeem';
import { CouponCreate } from './create';
import { CouponEdit } from './edit';
import { CouponList } from './list';
import { CouponShow } from './show';

const coupons = {
  name: 'coupons',
  icon: RedeemIcon,
  list: CouponList,
  show: CouponShow,
  edit: CouponEdit,
  create: CouponCreate,
  options: { label: '쿠폰 관리', permission: 'admin' },
};

export default coupons;
