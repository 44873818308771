import { SelectInput, SelectInputProps } from 'react-admin';
import { getEnumsChoices, pointHitoryRefEnums } from '../utils/enum';

const PointHistoryRefInput: React.FC<SelectInputProps> = () => {
  return (
    <SelectInput
      label="참조"
      source="ref"
      choices={getEnumsChoices(pointHitoryRefEnums)}
    />
  );
};

export default PointHistoryRefInput;
