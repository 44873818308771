import { CircularProgress, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ColorHash from 'color-hash';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Title, usePermissions } from 'react-admin';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import api from '../utils/api';

type Statistic = {
  date: Date;
  metric: string;
  value: number;
};
type ChartData = {
  date: string;
  [key: string]: any;
};

const colorHash = new ColorHash();

const Dashboard = () => {
  const [statisticsList, setStatisticsList] = useState<Statistic[][]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(1, 'week')
  );

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const { permissions, isLoading } = usePermissions();
  const isAdmin = permissions && permissions.includes('admin');
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAdmin) {
      return;
    }

    api
      .get<Statistic[][]>('dashboard', {
        startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      })
      .then((_statistics) => {
        setStatisticsList(_statistics);
      })
      .catch((_e) => {
        console.log('CANNOT GET STATISTICS');
      });
  }, [isAdmin, isLoading, startDate, endDate]);

  const chartData = statisticsList.reduce((ac: ChartData[], statistics) => {
    return statistics.map((statistic) => {
      const date = dayjs(statistic.date).add(9, 'hour').format('YYYY-MM-DD');
      const data = ac.find((x) => x.date === date);
      return {
        ...data,
        date,
        [statistic.metric]: statistic.value,
      };
    });
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAdmin) {
    return <>PIPER ADMIN</>;
  }

  return (
    <>
      <h1>DASHBOARD</h1>
      <p>매일 4시간에 한 번씩 통계를 업데이트 합니다</p>
      <Card>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
            &nbsp;~&nbsp;
            <DatePicker
              value={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
          </LocalizationProvider>
        </CardContent>
      </Card>
      <h2>기간 총 합</h2>
      <Grid container spacing={2}>
        <Title title="PIPER ADMIN" />
        {statisticsList.map((statistics) => {
          const [{ metric }] = statistics;
          const sum = statistics.reduce((ac, { value }) => {
            return ac + value;
          }, 0);
          return (
            <Grid key={metric} item xs={2}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <p>{metric}</p>
                  <h4>{sum}</h4>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <h2>일자 별</h2>
      <Card>
        <CardContent>
          <ResponsiveContainer height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              {Object.keys(chartData[0] || {})
                .filter((key) => key !== 'date')
                .map((key) => {
                  return (
                    <Line key={key} dataKey={key} stroke={colorHash.hex(key)} />
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default Dashboard;
