import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import StatusInput from '../../components/StatusInput';

export const QuestionCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="categoryId" reference="question-categories">
        <SelectInput label="카테고리" optionText="title" />
      </ReferenceInput>
      <TextInput label="질문" source="text" />
      <StatusInput />
    </SimpleForm>
  </Create>
);
