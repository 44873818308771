import { Button, Pagination } from '@mui/material';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
  WrapperField,
} from 'react-admin';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import api from '../../utils/api';

const Actions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  if (!record) {
    return;
  }

  async function confirmSettlement() {
    await api.post(`/settlement-requests/${record.id}/confirm`).catch((e) => {
      notify(e.message);
      throw e;
    });
    notify('정산이 완료되었습니다.');
    refresh();
  }
  async function decline() {
    const message = prompt('사유를 입력 해 주세요');
    await api
      .post(`/settlement-requests/${record.id}/decline`, { message })
      .catch((e) => {
        notify(e.message);
        throw e;
      });

    notify('정산이 거절되었습니다');
    refresh();
  }

  return (
    <TopToolbar>
      {record.status !== '1SUCCESS' && (
        <>
          <Button
            color="primary"
            onClick={() => {
              confirmSettlement();
            }}
          >
            정산완료
          </Button>
          <Button
            color="primary"
            onClick={() => {
              decline();
            }}
          >
            정산거절
          </Button>
        </>
      )}
    </TopToolbar>
  );
};

const SettlementRequestShow = () => {
  return (
    <Show actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="요청 정보">
          <TextField source="id" />
          <ArrayField source="details">
            <Datagrid>
              <ReferenceField source="contentId" reference="contents">
                <TextField source="title" />
              </ReferenceField>
              <NumberField source="quantity" />
              <CurrencyField source="amount" label="총 판매 금액" />
              <NumberField source="platformChargePercentage" />
            </Datagrid>
          </ArrayField>
          <CurrencyField source="totalAmount" label="총 판매 합계" />
          <CurrencyField source="platformCharge" label="플랫폼 수수료" />
          <CurrencyField
            source="finalSettlementAmount"
            label="최종 정산 금액"
          />
          <WrapperField label="계좌">
            <FunctionField
              render={({ bankAccount }) =>
                `${bankAccount.name} | ${bankAccount.owner} | ${bankAccount.number}`
              }
            />
          </WrapperField>
          <TextField source="status" />
          <DateTimeField source="createdAt" />
        </Tab>
        <Tab label="정산결과">
          <ReferenceManyField
            source="id"
            reference="settlement-results"
            target="settlementRequestId"
          >
            <>
              <Datagrid rowClick="show" isRowSelectable={() => false}>
                <TextField source="id" />
                <NumberField source="settlementAmount" />
                <TextField source="message" />
                <DateTimeField source="createdAt" />
              </Datagrid>
              <Pagination />
            </>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SettlementRequestShow;
