import { compact } from 'lodash';

export function formatAuthor(author: any) {
  const { legalName, user } = author;
  const name = compact([legalName, user.name]).join(' / ');
  return `${name} / ${user.email} (id:${user.id})`;
}

export function formatUser(user: any) {
  return `${user.name}_${user.email} (id:${user.id})`;
}
