import BackupTableIcon from '@mui/icons-material/BackupTable';
import { TemplateCreate } from './create';
import { TemplateEdit } from './edit';
import { TemplateList } from './list';
import { TemplateShow } from './show';

const templates = {
  name: 'templates',
  icon: BackupTableIcon,
  list: TemplateList,
  edit: TemplateEdit,
  show: TemplateShow,
  create: TemplateCreate,
  options: { label: '파이퍼 템플릿 관리', permission: 'admin' },
};

export default templates;
