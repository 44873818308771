import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  Link,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import BulkDeleteAndTrimButton from '../../components/BulkDeleteAndTrimButton';
import { ContentFields } from '../../components/ContentGrid';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import EditablePriorityField from '../../components/EditablePriorityField';
import ManyToManyAddModal from '../../components/ManyToManyAddModal';
import SizedImageField from '../../components/SizedImageField';
import StatusField from '../../components/StatusField';

export const SeriesesShow = () => (
  <Show
    actions={
      <DefaultShowActions
        deleteConfirm={{
          title: '시리즈를 삭제하시겠습니까?',
          content: '포함된 컨텐츠들의 시리즈 정보가 사라집니다.',
        }}
      />
    }
  >
    <TabbedShowLayout>
      <Tab label="시리즈 정보">
        <TextField source="id" />
        <ReferenceField
          label="카테고리"
          source="categoryId"
          reference="categories"
          emptyText="-"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" label="제목" />
        <TextField source="description" label="설명" />
        <NumberField source="discountRate" label="할인율" />
        <SizedImageField
          source="coverImageUrl"
          label="커버이미지"
          width={200}
          height={300}
        />
        <StatusField />
        <BooleanField source="isCompleted" label="완결" />
        <NumberField source="averageRating" label="평균 평점" />
        <DateTimeField source="latestPublishedAt" label="최신 발행일" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="updatedAt" />
      </Tab>
      <Tab label="포함된 컨텐츠">
        <SeriesContentsTab />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const SeriesContentsTab = () => {
  const { id } = useRecordContext();
  const [open, setOpen] = useState(false);
  const bulkDelete = (
    <BulkDeleteAndTrimButton
      resource="series-contents"
      trimUrl={(record) => `/serieses/${record.id}/reproduce`}
      label="시리즈에서 제외하기"
    />
  );

  return (
    <>
      <Link
        style={{ textAlign: 'right' }}
        to={{
          pathname: '/contents',
          search: `filter=${JSON.stringify({
            seriesContents: { seriesId: id },
          })}`,
        }}
      >
        <Button label="컨텐츠 목록에서 보기" size="large" />
      </Link>
      <Button
        variant="contained"
        size="large"
        label="컨텐츠 추가"
        startIcon={<AddIcon />}
        onClick={() => {
          setOpen(true);
        }}
      />
      <ReferenceManyField
        reference="series-contents"
        target="seriesId"
        source="id"
        sort={{ field: 'priority', order: 'ASC' }}
      >
        <>
          <Datagrid bulkActionButtons={bulkDelete}>
            <EditablePriorityField
              label="순서"
              resource="series-contents"
              sortBy="priority"
            />
            {ContentFields('content.')}
          </Datagrid>
          <Pagination />
        </>
      </ReferenceManyField>
      <ManyToManyAddModal
        label="컨텐츠"
        relation="series-contents"
        target="content"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        filter={{ '!seriesId': id }}
      />
    </>
  );
};
