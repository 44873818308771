import { PiperViewer } from '@piper-editor/editor';
import { useRecordContext } from 'react-admin';
import { getSizedImageUrl } from '../utils/image';
import LoadableImage from './LoadableImage';

const BlockContentsField = () => {
  const { type, contents } = useRecordContext();

  if (type === 'image') {
    return <ImageBlock jsonContents={contents} />;
  }

  return (
    <PiperViewer
      document={contents}
      renderDataPopup={({ data }) => {
        return (
          <span
            style={{
              border: '1px solid #333',
              padding: '2px 4px',
              width: 'max-content',
              maxWidth: '30rem',
              background: '#fff',
            }}
          >
            {data}
          </span>
        );
      }}
    />
  );
};

export default BlockContentsField;

const ImageBlock: React.FC<{ jsonContents: any }> = ({ jsonContents }) => {
  return (
    <>
      <LoadableImage src={getSizedImageUrl(jsonContents.url, 320)} />
      {jsonContents.caption && (
        <>
          <br />
          <span>{jsonContents.caption}</span>
        </>
      )}
    </>
  );
};
