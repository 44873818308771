import {
  Datagrid,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import CategoryField from '../../components/CategoryField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';

export const TemplateShow = () => (
  <Show actions={<DefaultShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="템플릿 이름" source="title" />
      <NumberField label="노출순서" source="priority" />
      <ReferenceManyField
        reference="template-questions"
        target="templateId"
        label="질문목록"
      >
        <Datagrid isRowSelectable={() => false}>
          <TextField source="priority" label="순서" />
          <ReferenceField
            source="questionId"
            reference="questions"
            label="카테고리"
          >
            <ReferenceField source="categoryId" reference="question-categories">
              <CategoryField />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField
            source="questionId"
            reference="questions"
            label="질문"
          >
            <TextField source="text" />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <DateTimeField source="createdAt" label="생성" />
      <DateTimeField source="updatedAt" label="최종수정" />
    </SimpleShowLayout>
  </Show>
);
