import { NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';

export const ContentRestrictionShow = () => (
  <Show actions={<DefaultShowActions />}>
    <SimpleShowLayout>
      <NumberField label="전체글 최소 글자수" source="minTotalCharacters" />
      <NumberField label="전체글 최대 글자수" source="maxTotalCharacters" />
      <NumberField label="최소 블록 수" source="minBlocks" />
      <NumberField label="최대 블록 수" source="maxBlocks" />
      <NumberField label="블록 당 최소 글자수" source="minBlockCharacters" />
      <NumberField label="블록 당 최대 글자수" source="maxBlockCharacters" />
      <NumberField
        label="블록 당 주석/링크 갯수"
        source="maxBlockAnnotations"
      />
      <NumberField label="제목 최대 글자수" source="maxTitleCharacters" />
      <NumberField label="태그 갯수" source="maxTags" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
      <TextField source="deletedAt" />
    </SimpleShowLayout>
  </Show>
);
