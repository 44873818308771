import { CircularProgress, ModalProps, TextField } from '@mui/material';
import { useState } from 'react';
import { Button } from 'react-admin';
import { useApiCallback } from '../hooks/api.hooks';
import CenterModal from './CenterModal';

type Props = Omit<ModalProps, 'children'> & {
  couponId: string;
};

const GenerateRandomCouponCodeModal: React.FC<Props> = (props) => {
  const { couponId, onClose } = props;
  const [generateAmount, setGenerateAmount] = useState<number>(0);

  const { load: generate, isLoading } = useApiCallback<
    boolean,
    GenerateRequestBody
  >('coupon-codes/generate-random-coupon-codes', (err) => {
    if (err) {
      alert(err.message);
      return;
    }
    alert('난수형 코드가 생성/저장 되었습니다.');
    onClose({}, 'escapeKeyDown');
  });

  return (
    <CenterModal {...props}>
      <TextField
        type="number"
        label="생성 갯수"
        value={generateAmount}
        onChange={(e) => {
          setGenerateAmount(Number(e.target.value));
        }}
      />
      <Button
        label="난수형 코드 생성/저장"
        variant="contained"
        fullWidth
        size="large"
        onClick={() => {
          generate({ couponId, generateAmount });
        }}
      />
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </CenterModal>
  );
};

export default GenerateRandomCouponCodeModal;

type GenerateRequestBody = {
  couponId: string;
  generateAmount: number;
};
