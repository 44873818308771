import { get } from 'lodash';
import {
  Datagrid,
  DatagridProps,
  Pagination,
  ReferenceField,
  TextField,
  WrapperField,
} from 'react-admin';
import AuthorField from './AuthorField';
import CurrencyField from './CurrencyField';
import DateTimeField from './DateTimeField';
import LinkField from './LinkField';
import SizedImageField from './SizedImageField';

export const ContentFields = (prefix: string = '') => [
  <LinkField
    link={(content) => `/contents/${get(content, prefix + 'id')}/show`}
    label="id"
    source={prefix + 'id'}
  />,
  <SizedImageField
    width={120}
    height={80}
    source={prefix + 'mainImageUrl'}
    label="메인이미지"
  />,
  <TextField source={prefix + 'title'} label="제목" />,
  <ReferenceField label="저자" source={prefix + 'authorId'} reference="authors">
    <AuthorField />
  </ReferenceField>,
  <CurrencyField source={prefix + 'price'} currency="파이" label="가격" />,
  <DateTimeField source={prefix + 'publishedAt'} label="발행일" />,
  <WrapperField label="생성/수정">
    <DateTimeField source={prefix + 'createdAt'} />
    <br />
    <DateTimeField source={prefix + 'updatedAt'} />
  </WrapperField>,
];

const ContentGrid: React.FC<DatagridProps> = (props) => {
  return (
    <>
      <Datagrid {...props}>{ContentFields()}</Datagrid>
      <Pagination />
    </>
  );
};

export default ContentGrid;
