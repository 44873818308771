import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

type Props = {
  label?: string;
  textAlign?: string;
  source: string;
  currency?: string;
};

const CurrencyField: React.FC<Props> = ({ source, currency = '원' }) => {
  const record = useRecordContext();
  const value = get(record, source);
  return (
    <span>
      {parseInt(value, 10).toLocaleString()} {currency}
    </span>
  );
};

CurrencyField.defaultProps = { label: '가격', textAlign: 'right' };

export default CurrencyField;
