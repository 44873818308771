import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';

type Props = {
  source?: string;
  label?: string;
};

const NotificationTargetField: React.FC<Props> = ({
  source = 'targetGroup',
  label,
}) => {
  const record = useRecordContext();
  const targetGroup = get(record, source);

  return (
    <Labeled label={label}>
      <span>{getTargetGroupText(targetGroup)}</span>
    </Labeled>
  );
};

NotificationTargetField.defaultProps = { label: '알림 받을 유저' };

export default NotificationTargetField;

function getTargetGroupText(targetGroup: string) {
  switch (targetGroup) {
    case 'all-users':
      return '모든 사용자';
    case 'all-authors':
      return '모든 저자';
    default:
      return '-';
  }
}
