import { useCallback, useEffect, useState } from 'react';
import api from '../utils/api';

export function useApiEffect<T>(path: string, dep: any = true) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!dep) {
      return;
    }

    const delay = setTimeout(() => {
      api
        .get<T>(path)
        .then((_data) => {
          setData(_data);
        })
        .catch((_error: Error) => {
          setError(_error);
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return () => {
      clearTimeout(delay);
    };
  }, [dep]);

  return { data, isLoading: isLoading || (!data && !error), error };
}

export function useApiCallback<ResData, ReqParam>(
  path: string,
  callback: (err: Error, data: ResData) => void
) {
  const [isLoading, setLoading] = useState(false);

  const load = useCallback(
    (param: ReqParam) => {
      if (isLoading) {
        return;
      }

      setLoading(true);
      api
        .post<ReqParam, ResData>(path, param)
        .then((_data) => {
          callback(null, _data);
        })
        .catch((_error: Error) => {
          callback(_error, null);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [isLoading, path]
  );

  return { load, isLoading };
}
