import { Datagrid, List, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import HexColorField from '../../components/HexColorField';

export const QuestionCategoryList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <HexColorField label="색상" source="hexColor" />
      <TextField label="카테고리명" source="title" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
