import {
  ArrayInput,
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
} from 'react-admin';

export const PointEventExecutionCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <ReferenceInput
        source="pointEventId"
        reference="point-events"
        label="이벤트"
      >
        <AutocompleteInput
          fullWidth
          source="title"
          label="이벤트"
          optionText={(pointEvents) => {
            return `[ID: ${pointEvents.id}] [제목: ${pointEvents.title}]`;
          }}
        />
      </ReferenceInput>
      <ArrayInput source="users">
        <SimpleFormIterator>
          <ReferenceInput source="id" reference="users">
            <AutocompleteInput
              fullWidth
              optionText={(user) => {
                return `[ID: ${user.id}] [이름: ${user.name}] [이메일: ${user.email}]`;
              }}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
