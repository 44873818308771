import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { PointEventExecutionCreate } from './create';
import { PointEventExecutionList } from './list';
import { PointEventExecutionShow } from './show';

const pointEventExecutions = {
  name: 'point-event-executions',
  icon: PlaylistPlayIcon,
  list: PointEventExecutionList,
  show: PointEventExecutionShow,
  create: PointEventExecutionCreate,
  options: { label: '포인트 이벤트 실행 관리', permission: 'admin' },
};
export default pointEventExecutions;
