import { Edit, NumberInput, SimpleForm } from 'react-admin';

export const ContentRestrictionEdit = () => (
  <Edit>
    <SimpleForm>
      <NumberInput label="전체글 최소 글자수" source="minTotalCharacters" />
      <NumberInput label="전체글 최대 글자수" source="maxTotalCharacters" />
      <NumberInput label="최소 블록 수" source="minBlocks" />
      <NumberInput label="최대 블록 수" source="maxBlocks" />
      <NumberInput label="블록 당 최소 글자수" source="minBlockCharacters" />
      <NumberInput label="블록 당 최대 글자수" source="maxBlockCharacters" />
      <NumberInput
        label="블록 당 주석/링크 갯수"
        source="maxBlockAnnotations"
      />
      <NumberInput label="제목 최대 글자수" source="maxTitleCharacters" />
      <NumberInput label="태그 갯수" source="maxTags" />
    </SimpleForm>
  </Edit>
);
