import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { formatUser } from '../../utils/format';

const SettlementResultCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput source="userId" reference="users">
          <AutocompleteInput
            fullWidth
            source="q"
            label="이름 / 이메일"
            optionText={formatUser}
          />
        </ReferenceInput>
        <NumberInput source="settlementAmount" label="정산 금액" />
        <TextInput source="message" label="사유" />
      </SimpleForm>
    </Create>
  );
};

export default SettlementResultCreate;
