import {
  ArrayField,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import BlockContentsField from '../../components/BlockContentsField';

const PublishedContentShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="title" />
        <ArrayField source="blocks">
          <Datagrid isRowSelectable={() => false}>
            <BlockContentsField />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default PublishedContentShow;
