import { MenuItem, ModalProps, Select } from '@mui/material';
import { useState } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  List,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  WrapperField,
} from 'react-admin';
import AsyncAutoComplete from '../../components/AsyncAutoComplete';
import BulkUpdateAtOnceButton from '../../components/BulkUpdateAtOnceButton';
import CenterModal from '../../components/CenterModal';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import QuickFilter from '../../components/QuickFilter';
import SeriesList from '../../components/SeriesesField';
import SizedImageField from '../../components/SizedImageField';
import UserField from '../../components/UserField';
import api from '../../utils/api';

const contentFilters = [
  <TextInput label="제목" source="q" alwaysOn />,
  <QuickFilter label="발행완료" source="isPublished" defaultValue="true" />,
  <ReferenceInput label="저자" source="userId" reference="users">
    <AutocompleteInput
      source="q"
      label="저자명 / 이메일"
      optionText={(user) => `${user.id}_${user.name}(${user.email})`}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="시리즈"
    source="seriesContents.seriesId"
    reference="serieses"
  >
    <AutocompleteInput
      sx={{ width: 300 }}
      source="q"
      label="시리즈명"
      optionText={(series) => series.title}
    />
  </ReferenceInput>,
];

export const ContentList = () => {
  const [statusUpdateOpen, setStatusUpdateOpen] = useState(false);
  const [seriesAddOpen, setSeriesAddOpen] = useState(false);

  return (
    <List exporter={false} filters={contentFilters}>
      <>
        <Datagrid
          rowClick="show"
          bulkActionButtons={
            <>
              <Button
                label="상태 일괄변경"
                onClick={() => {
                  setStatusUpdateOpen(true);
                }}
                variant="outlined"
              />
              <Button
                label="시리즈 일괄추가"
                onClick={() => setSeriesAddOpen(true)}
                variant="outlined"
              />
            </>
          }
        >
          <TextField source="id" />
          <ReferenceArrayField
            label="시리즈"
            source="seriesIds"
            reference="serieses"
          >
            <SeriesList />
          </ReferenceArrayField>
          <TextField label="제목" source="title" />
          <SizedImageField
            width={120}
            height={80}
            label="메인이미지"
            source="thumbnail"
          />
          <ReferenceField
            label="저자"
            source="userId"
            reference="users"
            link="show"
          >
            <UserField />
          </ReferenceField>
          <ReferenceField
            label="카테고리"
            source="id"
            reference="contents/categories"
            sortable={false}
          >
            <ArrayField source="categories">
              <SingleFieldList>
                <ChipField source="title" />
              </SingleFieldList>
            </ArrayField>
          </ReferenceField>
          <CurrencyField source="price" currency="파이" label="가격" />
          <WrapperField label="템플릿">
            <TextField
              label="선택된 템플릿 제목"
              source="contentSelectedTemplate.selectedTemplate.title"
            />
          </WrapperField>
          <BooleanField source="isPublished" label="발행?" />
          <WrapperField label="생성/수정">
            <DateTimeField source="createdAt" />
            <br />
            <DateTimeField source="updatedAt" />
          </WrapperField>
        </Datagrid>
        <BulkSeriesUpdateModal
          open={seriesAddOpen}
          onClose={() => {
            setSeriesAddOpen(false);
          }}
        />
        <BulkStatusUpdateModal
          open={statusUpdateOpen}
          onClose={() => {
            setStatusUpdateOpen(false);
          }}
        />
      </>
    </List>
  );
};

const BulkSeriesUpdateModal: React.FC<Omit<ModalProps, 'children'>> = (
  props
) => {
  const [seriesId, setSeriesId] = useState('');
  return (
    <CenterModal {...props}>
      <AsyncAutoComplete
        label="시리즈"
        request={(text) => {
          return api.get(`/serieses?q=${text}`);
        }}
        optionText={({ title }) => title}
        onChange={(option) => setSeriesId(option.id)}
      />

      <BulkUpdateAtOnceButton
        resource="contents"
        sx={{ marginTop: 2 }}
        variant="contained"
        size="large"
        fullWidth
        label="일괄 추가하기"
        data={{ seriesId }}
        onSuccess={() => {
          props.onClose({}, 'escapeKeyDown');
        }}
      />
    </CenterModal>
  );
};

const BulkStatusUpdateModal: React.FC<Omit<ModalProps, 'children'>> = (
  props
) => {
  const [status, setStatus] = useState('ACTIVE');

  return (
    <CenterModal {...props}>
      <Select
        value={status}
        fullWidth
        onChange={(e) => {
          setStatus(e.target.value);
        }}
      >
        <MenuItem value="ACTIVE">활성</MenuItem>
        <MenuItem value="INACTIVE">비활성</MenuItem>
      </Select>
      <BulkUpdateAtOnceButton
        resource="contents"
        variant="contained"
        label="변경하기"
        data={{ status }}
        size="large"
        onSuccess={() => {
          props.onClose({}, 'escapeKeyDown');
        }}
        fullWidth
        sx={{ marginTop: 2 }}
      />
    </CenterModal>
  );
};
