import { FunctionField, Link, TextField, TextFieldProps } from 'react-admin';

type Props = {
  link: (record: any) => string;
};
const LinkField: React.FC<TextFieldProps & Props> = (props) => {
  const { link } = props;
  return (
    <FunctionField
      render={(record: any) => {
        return (
          <Link to={link(record)}>
            <TextField {...props} />
          </Link>
        );
      }}
    />
  );
};

export default LinkField;
