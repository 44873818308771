import { SelectInput, SelectInputProps } from 'react-admin';

const NotificationTargetInput: React.FC<SelectInputProps> = (props) => {
  return (
    <SelectInput
      label="알림받을 유저"
      source="targetGroup"
      {...props}
      choices={[
        {
          id: 'all-users',
          name: '모든 사용자',
        },
        {
          id: 'all-authors',
          name: '모든 작가',
        },
      ]}
    />
  );
};

export default NotificationTargetInput;
