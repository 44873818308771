import EditOffIcon from '@mui/icons-material/EditOff';
import { ContentRestrictionCreate } from './create';
import { ContentRestrictionEdit } from './edit';
import { ContentRestrictionList } from './list';
import { ContentRestrictionShow } from './show';

const contentRestrictions = {
  name: 'content-restrictions',
  icon: EditOffIcon,
  list: ContentRestrictionList,
  show: ContentRestrictionShow,
  edit: ContentRestrictionEdit,
  create: ContentRestrictionCreate,
  options: { label: '에디터 제약사항 관리', permission: 'admin' },
};

export default contentRestrictions;
