import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { SeriesesCreate } from './create';
import { SeriesesEdit } from './edit';
import { SeriesesList } from './list';
import { SeriesesShow } from './show';

const serieses = {
  name: 'serieses',
  icon: LibraryBooksIcon,
  list: SeriesesList,
  show: SeriesesShow,
  edit: SeriesesEdit,
  create: SeriesesCreate,
  options: { label: '시리즈 관리', permission: 'admin' },
};

export default serieses;
