import axios, { Method } from 'axios';
import { API_HOST } from './config';
import { getToken } from './token';

class API {
  private async _call<T>(method: Method, path: string, params?: any) {
    const opt = method === 'GET' ? { params } : { data: params };

    const response = await axios
      .request<T>({
        headers: {
          'x-admin-token': getToken() || '',
        },
        method,
        baseURL: API_HOST,
        url: path,
        ...opt,
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          throw new Error(error.response?.data?.message);
        }
        throw error;
      });

    return response.data;
  }

  public get<Res = any>(path: string, params?: any) {
    return this._call<Res>('GET', path, params);
  }

  public post<ReqBody, Res>(path: string, body?: ReqBody) {
    return this._call<Res>('POST', path, body);
  }

  public put<ReqBody, Res>(path: string, body?: ReqBody) {
    return this._call<Res>('PUT', path, body);
  }
}

const api = new API();
export default api;
