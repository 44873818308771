import {
  Datagrid,
  List,
  ReferenceField,
  SelectInput,
  TextField,
} from 'react-admin';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import UserField from '../../components/UserField';

const filters = [
  <SelectInput
    source="status"
    choices={['WAITING', 'DECLINED', 'SUCCESS'].map((status) => ({
      id: status,
      name: status,
    }))}
  />,
];

const SettlementRequestList = () => {
  return (
    <List filters={filters} exporter={false}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <CurrencyField source="finalSettlementAmount" label="정산요청 금액" />
        <TextField source="status" label="상태" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default SettlementRequestList;
