import { SelectInput } from 'react-admin';
import { getEnumsChoices, pointPackagePromotionEnums } from '../utils/enum';

const PointPackagePromotionInput = ({ source, label }) => {
  return (
    <SelectInput
      choices={getEnumsChoices(pointPackagePromotionEnums)}
      source={source}
      label={label}
    />
  );
};

export default PointPackagePromotionInput;
