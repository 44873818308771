import QuizIcon from '@mui/icons-material/Quiz';
import { QuestionCreate } from './create';
import { QuestionEdit } from './edit';
import { QuestionList } from './list';
import { QuestionShow } from './show';

const questions = {
  name: 'questions',
  icon: QuizIcon,
  list: QuestionList,
  show: QuestionShow,
  edit: QuestionEdit,
  create: QuestionCreate,
  options: { label: '질문 관리', permission: 'admin' },
};

export default questions;
