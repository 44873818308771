import {
  ArrayInput,
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

export const TemplateCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" />
      <NumberInput source="priority" />
      <ArrayInput source="templateQuestions">
        <SimpleFormIterator>
          <ReferenceInput source="questionId" reference="questions">
            <AutocompleteInput
              fullWidth
              source="q"
              optionText={(question) => {
                return `[${question.category.title}] ${question.text}`;
              }}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
