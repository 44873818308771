import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';
import { getEnumsText, pointHitoryRefEnums } from '../utils/enum';

const PointHistoryRefField = ({ source = 'ref', label }) => {
  const record = useRecordContext();
  const ref = get(record, source);

  return (
    <Labeled label={label}>
      <span>{getEnumsText(pointHitoryRefEnums)(ref)}</span>
    </Labeled>
  );
};

PointHistoryRefField.defaultProps = { label: '참조' };

export default PointHistoryRefField;
