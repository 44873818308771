import {
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import DefaultShowActions from '../../components/DefaultShowActions';
import EventExecutorTypeField from '../../components/PointEventExecutorTypeField';
import EventField from '../../components/PointEventField';
import UserField from '../../components/UserField';

export const PointEventExecutionShow = () => (
  <Show actions={<DefaultShowActions editable={false} />}>
    <TabbedShowLayout>
      <Tab label="이벤트 실행 기본정보">
        <TextField source="id" />
        <ReferenceField
          label="이벤트"
          source="pointEventId"
          reference="point-events"
        >
          <EventField />
        </ReferenceField>
        <ReferenceField label="지급받은 유저" source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <EventExecutorTypeField source="executor" />
        <NumberField source="points" label="지급 포인트" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
