import { useRecordContext } from 'react-admin';
import { useApiEffect } from '../hooks/api.hooks';

const UserTotalPointField = () => {
  const record = useRecordContext();

  const { data: point } = useApiEffect<number>(
    `users/${record.id}/total-points`
  );

  return <span>총 {point} 파이</span>;
};

UserTotalPointField.defaultProps = { label: '잔여 파이 갯수' };

export default UserTotalPointField;
