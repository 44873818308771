import { SelectInput, SelectInputProps } from 'react-admin';
import { campaignTypeEnums, getEnumsChoices } from '../utils/enum';

const CampaignTypeInput: React.FC<SelectInputProps> = () => {
  return (
    <SelectInput
      label="캠페인 유형"
      source="type"
      choices={getEnumsChoices(campaignTypeEnums)}
    />
  );
};

export default CampaignTypeInput;
