export function getSizedImageUrl(url: string, width?: number, height?: number) {
  const query = (() => {
    const params = [];
    if (width) {
      params.push(`width=${width}`);
    }

    if (width && height) {
      params.push(`aspect_ratio=${ratio(width, height)}`);
    }

    return params.join('&');
  })();

  return `${url}?${query}`;
}

const ratio = (x: number, y: number) => {
  const g = gcd(x, y);
  return `${x / g}:${y / g}`;
};

const gcd = (x: number, y: number) => {
  return y === 0 ? x : gcd(y, x % y);
};
