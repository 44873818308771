import {
  ImageField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import CampaignTypeField from '../../components/CampaignTypeField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import UserField from '../../components/UserField';

export const UserNotificationShow = () => (
  <Show actions={<DefaultShowActions editable={false} />}>
    <TabbedShowLayout>
      <Tab label="캠페인 정보">
        <TextField source="id" />
        <ReferenceField source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <CampaignTypeField source="type" />
        <ImageField
          fullWidth={false}
          label="썸네일 URL"
          source="thumbnailUrl"
          sx={{ '& img': { width: 300 } }}
        />
        <TextField source="link" label="링크 URL" />
        <TextField aria-multiline source="content" label="내용" />
        <DateTimeField source="checkedAt" label="확인일시" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
