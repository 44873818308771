import {
  SelectInput,
  SelectInputProps,
  useSimpleFormIteratorItem,
} from 'react-admin';
import { couponTargetEnums, getEnumsChoices } from '../utils/enum';
const CouponTargetInput: React.FC<SelectInputProps> = () => {
  const { index } = useSimpleFormIteratorItem();
  return (
    <SelectInput
      label="쿠폰 타겟(포인트/컨텐츠/시리즈)"
      source={`couponTargets.${index}.target`}
      choices={getEnumsChoices(couponTargetEnums)}
      required
    />
  );
};

export default CouponTargetInput;
