import {
  DeleteWithConfirmButton,
  DeleteWithUndoButton,
  EditButton,
  ListButton,
  TopToolbar,
  useRecordContext,
} from 'react-admin';

type Props = {
  leftActions?: JSX.Element;
  editable?: boolean;
  deletable?: boolean;
  deleteConfirm?: {
    title: string;
    content: string;
  };
};

const DefaultShowActions: React.FC<Props> = ({
  leftActions,
  editable = true,
  deletable = true,
  deleteConfirm,
}) => {
  const record = useRecordContext();
  if (!record) {
    return;
  }

  return (
    <TopToolbar>
      <div style={{ position: 'absolute', left: 0 }}>{leftActions}</div>
      <ListButton />
      {editable && <EditButton />}
      {deletable &&
        (deleteConfirm ? (
          <DeleteWithConfirmButton
            confirmTitle={deleteConfirm.title}
            confirmContent={deleteConfirm.content}
          />
        ) : (
          <DeleteWithUndoButton />
        ))}
    </TopToolbar>
  );
};

export default DefaultShowActions;
