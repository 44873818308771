import { Link, useRecordContext } from 'react-admin';

const PointEventField = () => {
  const event = useRecordContext();

  return (
    <Link to={`/point-events/${event.id}/show`}>
      <span>
        {event.id}_{event.title}
      </span>
    </Link>
  );
};

PointEventField.defaultProps = { label: '이벤트' };

export default PointEventField;
