import { useRecordContext } from 'react-admin';

type Props = {
  source?: string;
  label?: string;
};

const CancelStatusField: React.FC<Props> = ({ source }) => {
  const record = useRecordContext();
  const status = record[source];

  return (
    <div
      style={{
        maxWidth: 100,
        backgroundColor: getFieldColor(status),
        padding: '2px 4px',
        textAlign: 'center',
      }}
    >
      {getFieldText(status)}
    </div>
  );
};

export default CancelStatusField;

function getFieldText(status: string) {
  switch (status) {
    case 'PARTIAL':
      return '부분취소';
    case 'TOTAL':
      return '전액취소';
    default:
      return '-';
  }
}

function getFieldColor(status: string) {
  switch (status) {
    case 'PARTIAL':
      return 'yellow';
    case 'TOTAL':
      return 'red';
    default:
      return;
  }
}
