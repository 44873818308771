import { SelectInput } from 'react-admin';
import { getEnumsChoices, pointEventIdentifierEnums } from '../utils/enum';

const PointEventIdentifierTypeInput = () => {
  return (
    <SelectInput
      label="이벤트 식별"
      source="identifier"
      choices={getEnumsChoices(pointEventIdentifierEnums)}
    />
  );
};

export default PointEventIdentifierTypeInput;
