import { Create, DateTimeInput, SimpleForm, TextInput } from 'react-admin';
import CampaignTypeInput from '../../components/CampaignTypeInput';
import ImageUploader from '../../components/ImageUploader';
import NotificationTargetInput from '../../components/NotificationTargetInput';

const validateFieldData = (record) => {
  const errors: { [key: string]: string } = {};

  if (!record.type) {
    errors.type = '캠페인 유형은 필수입니다.';
  }

  if (!record.link) {
    errors.link = '링크는 필수입니다.';
  }

  if (!record.content) {
    errors.content = '내용은 필수입니다.';
  }

  if (!record.reservedAt) {
    errors.reservedAt = '예약일시는 필수입니다.';
  }

  return errors;
};

export const NotificationCampaignCreate = () => (
  <Create>
    <SimpleForm validate={validateFieldData}>
      <CampaignTypeInput source="type" />
      <ImageUploader source="thumbnailUrl" label="썸네일 URL" />
      <TextInput fullWidth source="link" label="링크 URL" />
      <TextInput fullWidth multiline source="content" label="내용" />
      <NotificationTargetInput />
      <DateTimeInput source="reservedAt" label="예약일시" />
    </SimpleForm>
  </Create>
);
