type Enum<T> = {
  value: T;
  text: string;
};

export const statusEnums: Enum<'ACTIVE' | 'INACTIVE'>[] = [
  { value: 'ACTIVE', text: '활성' },
  { value: 'INACTIVE', text: '비활성' },
];

export const campaignTypeEnums: Enum<
  'BENEFIT' | 'RECOMMENDATION' | 'CHALLENGE'
>[] = [
  { value: 'BENEFIT', text: '혜택' },
  { value: 'RECOMMENDATION', text: '추천' },
  { value: 'CHALLENGE', text: '챌린지' },
];

export const pointEventExecutorEnums: Enum<'ADMIN' | 'USER' | 'AUTO'>[] = [
  { value: 'ADMIN', text: '관리자' },
  { value: 'USER', text: '사용자' },
  { value: 'AUTO', text: '자동' },
];

export const pointEventIdentifierEnums: Enum<
  'SIGN_UP' | 'COUPON' | 'INVITER' | 'INVITEE' | 'CUSTOM'
>[] = [
  { value: 'SIGN_UP', text: '회원가입' },
  { value: 'COUPON', text: '쿠폰' },
  { value: 'INVITER', text: '친구초대(추천인)' },
  { value: 'INVITEE', text: '친구초대(가입자)' },
  { value: 'CUSTOM', text: '임의지급' },
];

export const pointEventRestrictionEnums: Enum<'ONCE'>[] = [
  { value: 'ONCE', text: '1 회' },
];

export const progressStatusEnums: Enum<
  'READY' | 'SUCCESS' | 'FAILED' | 'CANCELED'
>[] = [
  { value: 'READY', text: '준비' },
  { value: 'SUCCESS', text: '성공' },
  { value: 'FAILED', text: '실패' },
  { value: 'CANCELED', text: '취소' },
];

export const pointPackagePromotionEnums: Enum<'FIRST_PURCHASE'>[] = [
  { value: 'FIRST_PURCHASE', text: '첫 결제 혜택' },
];

export const ratingEnums: Enum<'contents' | 'challenges'>[] = [
  { value: 'contents', text: '컨텐츠' },
  { value: 'challenges', text: '챌린지' },
];

export const couponTypeEnums: Enum<'FIXED' | 'ISSUANCE'>[] = [
  { value: 'FIXED', text: '고정형' },
  { value: 'ISSUANCE', text: '발급형' },
];

export const couponTargetEnums: Enum<
  'point_events' | 'contents' | 'serieses'
>[] = [
  { value: 'point_events', text: '포인트' },
  { value: 'contents', text: '컨텐츠' },
  { value: 'serieses', text: '시리즈' },
];

export const pointHitoryRefEnums: Enum<
  | 'content_purchases'
  | 'series_purchases'
  | 'point_package_purchases'
  | 'challenge_purchases'
  | 'challenge_mission_rewards'
  | 'challenge_review_rewards'
  | 'point_event_executions'
  | 'cancel'
>[] = [
  { value: 'content_purchases', text: '컨텐츠 구매' },
  { value: 'series_purchases', text: '시리즈 구매' },
  { value: 'point_package_purchases', text: '파이묶음 구매' },
  { value: 'challenge_purchases', text: '챌린지 구매' },
  { value: 'challenge_mission_rewards', text: '챌린지 미션 보상' },
  { value: 'challenge_review_rewards', text: '챌린지 리뷰 보상' },
  { value: 'point_event_executions', text: '이벤트 지급' },
  { value: 'cancel', text: '취소' },
];

export function getEnumsText<T>(enums: Enum<T>[]) {
  return (value: T) => {
    const { text } = enums.find((x) => x.value === value) || {};
    return text;
  };
}

export function getEnumsChoices<T>(enums: Enum<T>[]) {
  return enums.map((x) => {
    return {
      id: x.value,
      name: x.text,
    };
  });
}
