import { omit } from 'lodash';
import jsonServerProvider from 'ra-data-json-server';
import { Admin, Resource, ResourceProps } from 'react-admin';
import Dashboard from './components/Dashboard';
import CustomLayout from './layouts/custom-layout';
import admins from './resources/admins';
import banners from './resources/banners';
import categories from './resources/categories';
import contentRestrictions from './resources/contentRestrictions';
import contents from './resources/contents';
import coupons from './resources/coupons';
import couponUsages from './resources/couponUsages';
import externalPages from './resources/externalPages';
import notificationCampaigns from './resources/notificationCampaigns';
import pointEventExecutions from './resources/pointEventExecutions';
import pointEvents from './resources/pointEvents';
import pointHistories from './resources/pointHistories';
import pointPackages from './resources/pointPacakges';
import publishedContents from './resources/published-contents';
import questionCategories from './resources/questionCategories';
import questions from './resources/questions';
import replies from './resources/replies';
import serieses from './resources/serieses';
import settlementRequests from './resources/settlement-requests';
import settlementResults from './resources/settlement-results';
import templates from './resources/templates';
import userNotifications from './resources/userNotifications';
import userPayments from './resources/userPayments';
import users from './resources/users';
import authProvider from './utils/authProvider';
import { API_HOST } from './utils/config';
import httpClient from './utils/http-client';

const dataProvider = jsonServerProvider(API_HOST, httpClient);

const App = () => (
  <Admin
    layout={CustomLayout}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    requireAuth
  >
    {(permissions: string[]) => {
      function getResource(resource: ResourceProps) {
        const permission = resource.options?.permission as string;

        if (!!permission && !permissions.includes(permission)) {
          return omit(resource, ['list', 'edit', 'show', 'create']);
        }

        return resource;
      }

      return [
        <Resource {...getResource(banners)} />,
        <Resource {...getResource(notificationCampaigns)} />,
        <Resource {...getResource(userNotifications)} />,
        <Resource {...getResource(users)} />,
        <Resource {...getResource(userPayments)} />,
        <Resource {...getResource(serieses)} />,
        <Resource {...getResource(contents)} />,
        <Resource {...getResource(templates)} />,
        <Resource {...getResource(questionCategories)} />,
        <Resource {...getResource(questions)} />,
        <Resource {...getResource(contentRestrictions)} />,
        <Resource {...getResource(pointEvents)} />,
        <Resource {...getResource(pointEventExecutions)} />,
        <Resource {...getResource(pointPackages)} />,
        <Resource {...getResource(pointHistories)} />,
        <Resource {...getResource(admins)} />,
        <Resource {...getResource(externalPages)} />,
        <Resource {...getResource(categories)} />,
        <Resource {...getResource(coupons)} />,
        <Resource {...getResource(couponUsages)} />,
        <Resource {...getResource(publishedContents)} />,
        <Resource {...getResource(replies)} />,
        <Resource {...getResource(settlementRequests)} />,
        <Resource {...getResource(settlementResults)} />,
      ];
    }}
  </Admin>
);

export default App;
