import {
  AutocompleteInput,
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import SizedImageField from '../../components/SizedImageField';
import StatusField from '../../components/StatusField';
import UserField from '../../components/UserField';

const filters = [
  <ReferenceInput source="categoryId" reference="categories" label="카테고리">
    <AutocompleteInput source="q" label="카테고리명" optionText="title" />
  </ReferenceInput>,
];

export const SeriesesList = () => (
  <List exporter={false} filters={filters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField reference="users" source="userId" label="회원">
        <UserField />
      </ReferenceField>
      <TextField source="title" label="제목" />
      <SizedImageField
        source="coverImageUrl"
        label="커버이미지"
        width={100}
        height={150}
      />
      <StatusField />
      <ReferenceField
        reference="serieses/contents-counts"
        source="id"
        link={false}
        label="컨텐츠 갯수"
        sortable={false}
      >
        <WrapperField>
          총: <NumberField source="totalCount" />
          <br />
          무료: <NumberField source="freeCount" />
        </WrapperField>
      </ReferenceField>
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
