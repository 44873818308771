import { Chip } from '@mui/material';
import { useTranslate } from 'react-admin';

type Props = {
  label: string;
  source: string;
  defaultValue: any;
};

const QuickFilter: React.FC<Props> = ({ label }) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

export default QuickFilter;
