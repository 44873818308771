import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { formatUser } from '../../utils/format';

export const ContentEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput fullWidth source="title" label="제목" />
      <ReferenceArrayInput source="categoryIds" reference="categories">
        <AutocompleteArrayInput
          optionText={(category) => category.title}
          label="카테고리"
        />
      </ReferenceArrayInput>

      <ReferenceInput source="userId" reference="users" label="저자">
        <AutocompleteInput
          fullWidth
          source="q"
          label="저자명 / 이메일"
          optionText={formatUser}
        />
      </ReferenceInput>
      <ReferenceArrayInput
        source="seriesIds"
        reference="serieses"
        label="시리즈"
      >
        <AutocompleteArrayInput
          fullWidth
          source="title"
          label="시리즈"
          optionText={(series) => series.title}
        />
      </ReferenceArrayInput>
      <NumberInput source="price" label="가격 (파이)" />
      <NumberInput source="platformChargePercentage" label="수수료율 (%)" />
    </SimpleForm>
  </Edit>
);
