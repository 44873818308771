import Icon from '@mui/icons-material/RequestQuote';
import SettlementResultCreate from './create';
import SettlementResultList from './list';

const settlementResults = {
  name: 'settlement-results',
  icon: Icon,
  list: SettlementResultList,
  create: SettlementResultCreate,
  options: { label: '정산 결과' },
};

export default settlementResults;
