import {
  Datagrid,
  List,
  NumberField,
  TextField,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';

export const ContentRestrictionList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <WrapperField label="전체 글자수" textAlign="center">
        <NumberField source="minTotalCharacters" />
        -<NumberField source="maxTotalCharacters" />
      </WrapperField>
      <WrapperField label="블록수" textAlign="center">
        <NumberField source="minBlocks" />
        -<NumberField source="maxBlocks" />
      </WrapperField>
      <WrapperField label="블록 당 글자수" textAlign="center">
        <NumberField source="minBlockCharacters" />
        -<NumberField source="maxBlockCharacters" />
      </WrapperField>
      <NumberField label="블록 당 주석/링크" source="maxBlockAnnotations" />
      <NumberField label="제목 글자수" source="maxTitleCharacters" />
      <NumberField label="태그 갯수" source="maxTags" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
