import dayjs from 'dayjs';
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import UserField from '../../components/UserField';

const SettlementResultList = () => {
  return (
    <List>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <ReferenceField
          source="settlementRequestId"
          reference="settlement-requests"
          link="show"
          label="정산 요청"
        >
          <FunctionField
            render={({ id, createdAt }) =>
              `${id} | ${dayjs(createdAt).format('YY/MM/DD HH:mm:ss')}`
            }
          />
        </ReferenceField>
        <CurrencyField source="settlementAmount" label="정산 금액" />
        <TextField source="message" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default SettlementResultList;
