import { useRecordContext } from 'react-admin';
import { campaignTypeEnums, getEnumsText } from '../utils/enum';

const CampaignTypeField = ({ source }) => {
  const record = useRecordContext();
  const type = record[source];

  return <div>{getEnumsText(campaignTypeEnums)(type)}</div>;
};

CampaignTypeField.defaultProps = { label: '캠페인 유형' };

export default CampaignTypeField;
