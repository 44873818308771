import { Datagrid, List, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import SizedImageField from '../../components/SizedImageField';
import StatusField from '../../components/StatusField';

export const CategoryList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <StatusField source="status" />
      <SizedImageField
        label="이미지"
        source="imageUrl"
        width={100}
        sortable={false}
      />
      <DateTimeField label="생성" source="createdAt" />
      <DateTimeField label="수정" source="updatedAt" />
    </Datagrid>
  </List>
);
