import { useCallback, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import api from '../utils/api';
import PieIcon from './PieIcon';

const PieIconInput = () => {
  const { field } = useController({ name: 'iconUrl' });

  const [icons, setIcons] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const { value } = field;
  const onChange = useCallback(
    (value: string) => {
      field.onChange({
        target: { value },
      });
    },
    [field]
  );

  useEffect(() => {
    if (icons.length > 0) {
      return;
    }

    api.get<string[]>('/point-packages/pie-icons').then((data) => {
      setIcons(data);
      if (!field.value) {
        onChange(data[0]);
      }
    });
  }, [icons, field, onChange]);

  return (
    <div style={{ position: 'relative' }}>
      <PieIcon
        src={value}
        onClick={() => {
          setOpen(!open);
        }}
      />
      {open && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '100%',
            width: 'max-content',
          }}
        >
          {icons.map((icon, index) => {
            return (
              <PieIcon
                src={icon}
                key={`icon-${index}`}
                onClick={() => {
                  onChange(icon);
                  setOpen(false);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

PieIconInput.defaultProps = { label: '아이콘' };

export default PieIconInput;
