import {
  ImageField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import HexColorField from '../../components/HexColorField';
import StatusField from '../../components/StatusField';

export const BannerShow = () => (
  <Show actions={<DefaultShowActions />}>
    <TabbedShowLayout>
      <Tab label="배너 기본정보">
        <TextField source="id" />
        <TextField label="배너 설명" source="description" />
        <TextField label="배너 링크" source="link" />
        <HexColorField
          label="배너 배경색(16진수)"
          source="backgroundHexColor"
        />
        <ImageField
          fullWidth={false}
          label="배너 이미지 URL"
          source="bannerImageUrl"
          sx={{ '& img': { width: 300 } }}
        />
        <StatusField label="공개여부" />
        <TextField label="배너 노출순서" source="priority" />
        <TextField label="메인 텍스트" source="mainText" />
        <HexColorField
          label="메인 텍스트색(16진수)"
          source="mainTextHexColor"
        />
        <TextField label="서브 텍스트" source="subText" />
        <HexColorField label="서브 텍스트색(16진수)" source="subTextHexColor" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
