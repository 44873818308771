import { Edit, SimpleForm, TextInput } from 'react-admin';

export const ExternalPageEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="type" label="타입" />
      <TextInput source="link" label="외부 페이지 링크" fullWidth />
    </SimpleForm>
  </Edit>
);
