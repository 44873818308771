import { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { useController } from 'react-hook-form';
import api from '../../utils/api';
import { formatUser } from '../../utils/format';

export const ContentCreate = () => (
  <Create>
    <TabbedForm>
      <FormTab label="기본정보" sx={{ maxWith: 600 }}>
        <ReferenceArrayInput source="categoryIds" reference="categories">
          <AutocompleteArrayInput
            optionText={(category) => category.title}
            label="카테고리"
          />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="seriesIds"
          reference="serieses"
          label="시리즈"
        >
          <AutocompleteArrayInput
            fullWidth
            source="title"
            label="시리즈"
            optionText={(series) => series.title}
          />
        </ReferenceArrayInput>

        <TextInput fullWidth source="title" label="제목" />
        <ReferenceInput source="userId" reference="users">
          <AutocompleteInput
            fullWidth
            source="q"
            label="저자명 / 이메일"
            optionText={formatUser}
          />
        </ReferenceInput>
        <ReferenceInput source="templateId" reference="templates">
          <SelectInput
            fullWidth
            optionText={(template) => template.title}
            label="템플릿"
          />
        </ReferenceInput>
        <SelectedQuestions />
        <NumberInput source="price" defaultValue={5} label="가격 (파이)" />
        <NumberInput
          source="platformChargeAmount"
          defaultValue={10}
          label="수수료율(%)"
        />
      </FormTab>
    </TabbedForm>
  </Create>
);

const SelectedQuestions = () => {
  const { field } = useController({ name: 'templateId' });
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (!field.value) {
      return;
    }

    api
      .get<any>(`/template-questions?templateId=${field.value}`)
      .then((templateQuestions) => {
        const _questions = templateQuestions.map(({ question }) => question);
        setQuestions(_questions);
      });
  }, [field.value]);

  return (
    questions.length > 0 && (
      <div style={{ border: '1px solid #000', padding: 5 }}>
        <p style={{ fontWeight: 'bold' }}>템플릿에 포함된 질문</p>
        {questions.map(({ text }) => (
          <p>{text}</p>
        ))}
      </div>
    )
  );
};
