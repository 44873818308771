import {
  Datagrid,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import HexColorField from '../../components/HexColorField';

export const QuestionCategoryShow = () => (
  <Show actions={<DefaultShowActions />}>
    <TabbedShowLayout>
      <Tab label="정보">
        <TextField source="id" />
        <TextField label="카테고리명" source="title" />
        <HexColorField label="색상" source="hexColor" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="updatedAt" />
      </Tab>
      <Tab label="등록된 질문목록">
        <ReferenceManyField reference="questions" target="categoryId">
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField label="질문" source="text" />
            <DateTimeField source="createdAt" />
            <DateTimeField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
