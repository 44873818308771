import { get } from 'lodash';
import { useRecordContext } from 'react-admin';
import { getEnumsText, statusEnums } from '../utils/enum';

type Props = {
  label?: string;
  source?: string;
};
const StatusField: React.FC<Props> = ({ source = 'status' }) => {
  const record = useRecordContext();
  const status = get(record, source);

  return (
    <span
      style={{
        width: 'fit-content',
        padding: '4px 8px',
        background: status === 'ACTIVE' ? '#57e389' : '#f66151',
      }}
    >
      {getEnumsText(statusEnums)(status)}
    </span>
  );
};

StatusField.defaultProps = { label: '상태' };

export default StatusField;
