import { useState } from 'react';
import {
  Button,
  Datagrid,
  Labeled,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
  WrapperField,
} from 'react-admin';
import CouponTargetField from '../../components/CouponTargetField';
import CouponTargetResourceField from '../../components/CouponTargetResourceField';
import CouponTypeField from '../../components/CouponTypeField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import GenerateRandomCouponCodeModal from '../../components/GenerateRandomCouponCodeModal';
import UserField from '../../components/UserField';

export const CouponShow = () => {
  const { record } = useShowController();
  const [open, setOpen] = useState(false);

  return (
    <Show actions={<DefaultShowActions />}>
      <TabbedShowLayout>
        <Tab label="쿠폰 기본정보">
          <TextField source="id" />
          <TextField source="title" label="제목" />
          <CouponTypeField />
          {record?.type === 'FIXED' && (
            <>
              <Labeled label="고정형 코드">
                <TextField source="fixedCode" />
              </Labeled>
              <Labeled label="고정형 최대 발급 갯수">
                <NumberField source="maxProvideCount" />
              </Labeled>
            </>
          )}
          <DateTimeField source="startAt" label="시작일" />
          <DateTimeField source="expiredAt" label="만료일" />
          <WrapperField label="생성/수정">
            <DateTimeField source="createdAt" />
            <br />
            <DateTimeField source="updatedAt" />
          </WrapperField>
        </Tab>
        <Tab label="쿠폰에 등록된 타겟">
          <ReferenceManyField reference="coupon-targets" target="couponId">
            <Datagrid isRowSelectable={() => false}>
              <CouponTargetField />
              <CouponTargetResourceField />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {record?.type === 'ISSUANCE' && (
          <Tab label="쿠폰에 등록된 코드">
            <ReferenceManyField reference="coupon-codes" target="couponId">
              <>
                <Datagrid>
                  <TextField source="id" />
                  <TextField label="코드" source="code" />
                  <ReferenceField
                    label="쿠폰 사용자"
                    source="userId"
                    reference="users"
                  >
                    <UserField />
                  </ReferenceField>
                  <DateTimeField source="createdAt" label="생성 일자" />
                  <DateTimeField source="updatedAt" label="최종 사용일자" />
                </Datagrid>
                <Pagination />
              </>
            </ReferenceManyField>
            <div style={{ textAlign: 'right' }}>
              <Button
                size="large"
                variant="outlined"
                label="코드 발급하기"
                onClick={() => {
                  setOpen(true);
                }}
              />
              <GenerateRandomCouponCodeModal
                couponId={record.id}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              />
            </div>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
