import { NumberInput, TextInput } from 'react-admin';
import { useController } from 'react-hook-form';

const FixedCouponField = () => {
  const { field } = useController({ name: 'type' });
  if (field.value !== 'FIXED') {
    return null;
  }

  return (
    <>
      <TextInput source="fixedCode" label="고정형 코드" />
      <NumberInput source="maxProvideCount" label="쿠폰 발급 갯수" min={0} />
    </>
  );
};

export default FixedCouponField;
