import PaidIcon from '@mui/icons-material/Paid';
import { UserPaymentList } from './list';
import { UserPaymentShow } from './show';

const userPayments = {
  name: 'user-payments',
  icon: PaidIcon,
  list: UserPaymentList,
  show: UserPaymentShow,
  options: { label: '회원 결제관리', permission: 'admin' },
};

export default userPayments;
