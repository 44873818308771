import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ImageUploader from '../../components/ImageUploader';
import StatusInput from '../../components/StatusInput';
import { formatUser } from '../../utils/format';

export const SeriesesCreate = () => (
  <Create redirect="list">
    <SimpleForm sx={{ maxWidth: 600 }}>
      <ReferenceInput source="userId" reference="users" label="저자">
        <AutocompleteInput
          fullWidth
          source="q"
          label="회원명 / 이메일"
          optionText={formatUser}
        />
      </ReferenceInput>
      <TextInput fullWidth source="title" label="제목" />
      <TextInput
        fullWidth
        source="description"
        label="설명"
        multiline
        minRows={3}
      />
      <ImageUploader
        width={200}
        height={300}
        source="coverImageUrl"
        label="커버이미지"
      />
      <StatusInput />
    </SimpleForm>
  </Create>
);
