import { get } from 'lodash';
import { useRecordContext } from 'react-admin';
import dayjs from 'dayjs';

type Props = {
  source: string;
  label?: string;
};

const DateTimeField: React.FC<Props> = ({ source }) => {
  const record = useRecordContext();
  const field = get(record, source);

  if (!field) {
    return <span>-</span>;
  }

  return <span>{dayjs(field).format('YY/MM/DD HH:mm:ss')}</span>;
};

export default DateTimeField;
