import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  Datagrid,
  FunctionField,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import ChangedPointField from '../../components/ChangedPointField';
import DateTimeField from '../../components/DateTimeField';
import PointHistoryRefField from '../../components/PointHistoryRefField';
import PointHistoryRefInput from '../../components/PointHistoryRefInput';
import PointTransactionList from '../../components/PointTransactionList';
import ProgressStatusField from '../../components/ProgressStatusField';
import RefundModal from '../../components/RefundModal';
import api from '../../utils/api';

const pointHistoriesFilters = [
  <TextInput resettable label="ID" source="id" />,
  <ReferenceInput label="회원" source="userId" reference="users">
    <AutocompleteInput
      source="q"
      label="회원명 / 이메일"
      optionText={(user) => `${user.id}_${user.name}(${user.email})`}
    />
  </ReferenceInput>,
  <PointHistoryRefInput alwaysOn />,
];

export const PointHistoryList = () => {
  return (
    <List filters={pointHistoriesFilters} exporter={false}>
      <Datagrid
        expandSingle
        rowClick="expand"
        expand={<PointReference />}
        isRowExpandable={() => true}
      >
        <TextField source="id" />
        <ReferenceField
          source="userId"
          reference="users"
          label="유저"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ChangedPointField source="point" label="충전/사용" />
        <ReferenceField
          source="id"
          reference="point-histories/purchases"
          label="상태"
          sortable={false}
        >
          <FunctionField
            label="상태"
            render={(record) => {
              if (record.purchase.status) {
                return <ProgressStatusField source="purchase.status" />;
              }
              if (record.purchase.chargeStatus) {
                return <ProgressStatusField source="purchase.chargeStatus" />;
              }
              return <></>;
            }}
          />
        </ReferenceField>
        <PointHistoryRefField />,
        <TextField source="reason" label="변경사유" />
        <DateTimeField source="createdAt" label="발생일" />
      </Datagrid>
    </List>
  );
};

type Purchase = {
  resource: string;
  resourceId: string;
  point: number;
};

const PointReference = () => {
  const record = useRecordContext();
  const { ref, refId } = record;
  const [purchase, setPurchase] = useState<Purchase>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    api.get<Purchase>(`purchases/${ref}/${refId}`).then((_purchase) => {
      setPurchase(_purchase);
    });
  }, [ref, refId]);

  const link = `/${purchase?.resource}/${purchase?.resourceId}`;
  return (
    <>
      <div>
        <div>
          링크:
          <Link to={link}>
            <Button>
              <span>{link}</span>
            </Button>
          </Link>
        </div>
        <b>트랜잭션</b>

        <PointTransactionList filter={{ pointHistoryId: record.id }}>
          <TextField source="id" label="트랜잭션 id" />
          <ReferenceField
            source="userPointId"
            reference="user-points"
            label="유저 파이"
            link={({ userPointId }) => {
              return `/user-points?filter=${JSON.stringify({
                id: userPointId,
              })}`;
            }}
          >
            <WrapperField>
              <span style={{ paddingRight: 2 }}>
                point id[
                <TextField source="id" />]
              </span>
              <span>
                (<TextField source="balance" /> /
                <TextField source="income" />)
              </span>
            </WrapperField>
          </ReferenceField>
          <ChangedPointField source="point" label="변동 파이" />
          <DateTimeField source="createdAt" label="트랜잭션 일자" />
        </PointTransactionList>

        <Button
          size="medium"
          variant="contained"
          label="충전/사용 내역 취소"
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            marginRight: 1,
            float: 'right',
          }}
        />
        {record && (
          <>
            <RefundModal
              pointHistory={record}
              open={open}
              onClose={() => setOpen(false)}
            />
          </>
        )}
      </div>
    </>
  );
};
