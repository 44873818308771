import ShortTextIcon from '@mui/icons-material/ShortText';
import RepliesList from './list';

const replies = {
  name: 'replies',
  icon: ShortTextIcon,
  list: RepliesList,
  options: { label: '댓글 관리' },
};

export default replies;
