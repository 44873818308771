import { SelectInput, SelectInputProps } from 'react-admin';

const StatusInput: React.FC<SelectInputProps> = (props) => {
  return (
    <SelectInput
      label="상태"
      {...props}
      source="status"
      emptyValue={'ACTIVE'}
      choices={[
        {
          id: 'ACTIVE',
          name: '활성',
        },
        {
          id: 'INACTIVE',
          name: '비활성',
        },
      ]}
    />
  );
};

export default StatusInput;
