import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
} from 'react-admin';

export const AdminCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <AutocompleteInput source="email" optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
