import { fetchUtils } from 'react-admin';
import { getToken } from './token';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.headers.set('x-admin-token', getToken());
  if (options.method === 'DELETE' && !options.body) {
    options.body = '{}';
  }

  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
