import { Link, useRecordContext } from 'react-admin';

const UserField = () => {
  const user = useRecordContext();

  return (
    <Link to={`/users/${user.id}/show`}>
      <span>{user.id}</span>_<span>{user.name}</span>(<span>{user.email}</span>)
    </Link>
  );
};

UserField.defaultProps = { label: '회원' };

export default UserField;
