import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';

type Props = {
  source?: string;
  label?: string;
};

const PaymentCardField: React.FC<Props> = ({ source, label }) => {
  const record = useRecordContext();
  const card = get(record, source);

  return (
    <Labeled label={label || source}>
      <>
        {card.company}|{card.cardType}|{card.ownerType}
        <br />
        {card.number}
      </>
    </Labeled>
  );
};

export default PaymentCardField;
