import { Show, SimpleShowLayout, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import SizedImageField from '../../components/SizedImageField';
import StatusField from '../../components/StatusField';

export const CategoryShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="제목" source="title" />
      <StatusField source="status" />
      <SizedImageField label="카테고리 이미지" source="imageUrl" width={500} />
      <TextField label="설명" source="description" />
      <DateTimeField label="생성" source="createdAt" />
      <DateTimeField label="수정" source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
