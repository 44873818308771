import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { BannerCreate } from './create';
import { BannerEdit } from './edit';
import { BannerList } from './list';
import { BannerShow } from './show';

const banners = {
  name: 'banners',
  icon: SupervisorAccountIcon,
  list: BannerList,
  show: BannerShow,
  edit: BannerEdit,
  create: BannerCreate,
  options: { label: '배너 관리', permission: 'admin' },
};

export default banners;
