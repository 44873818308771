import CookieIcon from '@mui/icons-material/Cookie';
import { PointHistoryList } from './list';

const pointHistories = {
  name: 'point-histories',
  icon: CookieIcon,
  list: PointHistoryList,
  options: { label: '파이 충전/사용내역', permission: 'admin' },
};

export default pointHistories;
