import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';
import { couponTargetEnums, getEnumsText } from '../utils/enum';

const CouponTargetField = ({ label }) => {
  const record = useRecordContext();
  const target = get(record, 'target');

  return (
    <Labeled label={label}>
      <div>{getEnumsText(couponTargetEnums)(target)}</div>
    </Labeled>
  );
};

CouponTargetField.defaultProps = { label: '쿠폰 타겟' };

export default CouponTargetField;
