import { ReactNode, useState } from 'react';
import {
  Datagrid,
  ListContextProvider,
  Pagination,
  useGetList,
  useList,
} from 'react-admin';

type Props = {
  filter: any;
  children: ReactNode;
};

const PointTransactionList: React.FC<Props> = ({ children, filter }) => {
  const [page, setPage] = useState(1);
  const perPage = 10;
  const sort = { field: 'id', order: 'DESC' };

  const { data, total, isLoading } = useGetList('point-transactions', {
    pagination: { page, perPage },
    sort,
    filter,
  });

  const listContext = useList({ data, isLoading, page, perPage });

  return (
    <ListContextProvider
      value={{
        ...listContext,
        total,
        page,
        perPage,
        setPage,
      }}
    >
      <div style={{ border: '1px solid gray', padding: 5 }}>
        <Datagrid isRowSelectable={() => false}>{children}</Datagrid>
        <Pagination rowsPerPageOptions={[10]} />
      </div>
    </ListContextProvider>
  );
};

export default PointTransactionList;
