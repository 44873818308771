import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import StatusInput from '../../components/StatusInput';

export const QuestionEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="categoryId" reference="question-categories">
        <SelectInput label="카테고리" optionText="title" />
      </ReferenceInput>
      <TextInput label="질문" source="text" />
      <StatusInput />
    </SimpleForm>
  </Edit>
);
