import { useRecordContext } from 'react-admin';

const AuthorField = () => {
  const author = useRecordContext();

  return (
    <>
      <span>
        {author.user.email} (id:{author.user.id})
      </span>
      <br />
      <span>
        {author.legalName || '저자명 없음'} / {author.user.name}
      </span>
    </>
  );
};

AuthorField.defaultProps = { label: '저자' };

export default AuthorField;
