import {
  Button,
  useDeleteMany,
  useListContext,
  useRecordContext,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../utils/api';

type Props = {
  resource: string;
  trimUrl: (record: any) => string;
  label: string;
};

const BulkDeleteAndTrimButton: React.FC<Props> = ({
  resource,
  trimUrl,
  label,
}) => {
  const record = useRecordContext();
  const { selectedIds, onUnselectItems, refetch } = useListContext();
  const [deleteMany] = useDeleteMany(
    resource,
    {
      ids: selectedIds,
    },
    {
      onSuccess: async () => {
        onUnselectItems();
        await api.post(trimUrl(record));
        refetch();
      },
    }
  );

  return (
    <Button
      startIcon={<DeleteIcon />}
      label={label}
      onClick={() => {
        deleteMany();
      }}
    />
  );
};

export default BulkDeleteAndTrimButton;
