import {
  Datagrid,
  FunctionField,
  Link,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import UserField from '../../components/UserField';

const RepliesList = () => {
  return (
    <List>
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          render={(reply: any) => {
            return (
              <Link to={`/${reply.ref}/${reply.refId}`}>
                {reply.ref === 'contents' ? '컨텐츠' : '시리즈'}-{' '}
                {reply.reference.reference.title}
              </Link>
            );
          }}
          label="컨텐츠/시리즈"
        />
        <ReferenceField source="userId" reference="users" label="유저">
          <UserField />
        </ReferenceField>
        <TextField source="comment" fullWidth />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default RepliesList;
