import { CircularProgress } from '@mui/material';
import { useState } from 'react';

type Props = {
  src: string;
  style?: React.CSSProperties;
};

const LoadableImage: React.FC<Props> = ({ src, style }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading && <CircularProgress />}
      <img
        style={style}
        src={src}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

export default LoadableImage;
