import CelebrationIcon from '@mui/icons-material/Celebration';
import { PointEventCreate } from './create';
import { PointEventEdit } from './edit';
import { PointEventList } from './list';
import { PointEventShow } from './show';

const pointEvents = {
  name: 'point-events',
  icon: CelebrationIcon,
  list: PointEventList,
  show: PointEventShow,
  edit: PointEventEdit,
  create: PointEventCreate,
  options: { label: '포인트 이벤트 관리', permission: 'admin' },
};

export default pointEvents;
