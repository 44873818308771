const ADMIN_TOKEN_ITEM = 'adminToken';

export function getToken() {
  return localStorage.getItem(ADMIN_TOKEN_ITEM);
}

export function setToken(token: string) {
  localStorage.setItem(ADMIN_TOKEN_ITEM, token);
}

export function removeToken() {
  localStorage.removeItem(ADMIN_TOKEN_ITEM);
}
