import { useRecordContext } from 'react-admin';
import { getEnumsText, pointEventRestrictionEnums } from '../utils/enum';

const PointEventRestrictionTypeField = ({ source }) => {
  const record = useRecordContext();
  const restriction = record[source];

  return <div>{getEnumsText(pointEventRestrictionEnums)(restriction)}</div>;
};

PointEventRestrictionTypeField.defaultProps = { label: '이벤트 지급 설정' };

export default PointEventRestrictionTypeField;
