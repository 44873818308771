const validateFixedCoupon = (record) => {
  const errors: { [key: string]: string } = {};

  if (record.type === 'FIXED') {
    if (!record.fixedCode) {
      errors.fixedCode = '쿠폰 타입이 고정형일 경우 고정형_코드가 필수입니다.';
    }
    if (!record.maxProvideCount) {
      errors.maxProvideCount =
        '쿠폰 타입이 고정형일 경우 쿠폰 발급 갯수가 필수입니다.';
    }
  }
  return errors;
};

export default validateFixedCoupon;
