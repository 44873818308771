import { Create, SimpleForm, TextInput } from 'react-admin';
import ImageUploader from '../../components/ImageUploader';
import StatusInput from '../../components/StatusInput';

export const CategoryCreate: React.FC = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput label="제목" source="title" />
        <ImageUploader source="imageUrl" label="카테고리 이미지" width={100} />
        <TextInput
          label="설명"
          source="description"
          multiline
          fullWidth
          minRows={3}
        />
        <StatusInput source="status" defaultValue={'ACTIVE'} />
      </SimpleForm>
    </Create>
  );
};
