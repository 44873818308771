import { useEffect } from 'react';
import { useController } from 'react-hook-form';

type Props = {
  source: string;
  label: string;
};

const HexColorInput: React.FC<Props> = ({ source, label }) => {
  const { field } = useController({ name: source });

  useEffect(() => {
    if (!field.value) {
      field.onChange({ target: { value: '#000000' } });
    }
  }, [field]);

  const value = field.value || '#000000';

  return (
    <>
      <h5>{label}</h5>
      <span>
        <input {...field} value={value} type="color" />
      </span>
    </>
  );
};

export default HexColorInput;
