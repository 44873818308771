import {
  ImageField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import CampaignTypeField from '../../components/CampaignTypeField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import NotificationTargetField from '../../components/NotificationTargetField';

export const NotificationCampaignShow = () => {
  const { record } = useShowController();
  return (
    <Show
      actions={
        <DefaultShowActions
          editable={record.status === 'SUCCESS' ? false : true}
        />
      }
    >
      <TabbedShowLayout>
        <Tab label="캠페인 정보">
          <TextField source="id" />
          <CampaignTypeField source="type" />
          <ImageField
            fullWidth={false}
            label="썸네일 URL"
            source="thumbnailUrl"
            sx={{ '& img': { width: 300 } }}
          />
          <TextField source="link" label="링크 URL" />
          <TextField source="content" label="내용" />
          <TextField source="status" label="스케쥴 상태" />
          <NotificationTargetField />
          <DateTimeField source="reservedAt" label="예약일시" />
          <DateTimeField source="createdAt" />
          <DateTimeField source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
