import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

type Props = {
  label?: string;
  source: string;
};

const StringListField: React.FC<Props> = ({ source }) => {
  const record = useRecordContext();
  const list = get(record, source);

  return (
    <ul style={{ paddingLeft: 20, margin: 0 }}>
      {list.map((item: string, index: number) => {
        return <li key={`${source}_${index}`}>{item}</li>;
      })}
    </ul>
  );
};

export default StringListField;
