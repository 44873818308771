import dayjs from 'dayjs';
import {
  ArrayInput,
  DateTimeInput,
  Edit,
  FormTab,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import CouponTargetIdInput from '../../components/CouponTargetIdInput';
import CouponTargetInput from '../../components/CouponTargetInput';
import CouponTypeInput from '../../components/CouponTypeInput';
import FixedCouponField from '../../components/FixedCouponField';
import validateFixedCoupon from '../../utils/validate/ValidateFixedCoupon';

export const CouponEdit = () => {
  const defaultStartDate = dayjs().startOf('day');
  const defaultEndDate = dayjs().add(1, 'week').endOf('day');

  return (
    <Edit mutationMode="pessimistic">
      <TabbedForm validate={validateFixedCoupon}>
        <FormTab label="쿠폰 정보">
          <TextInput source="title" label="제목" />
          <CouponTypeInput source="type" />
          <DateTimeInput
            source="startAt"
            label="시작일"
            defaultValue={defaultStartDate}
          />
          <DateTimeInput
            source="expiredAt"
            label="만료일"
            defaultValue={defaultEndDate}
          />
          <FixedCouponField />
        </FormTab>

        <FormTab label="쿠폰 타겟 정보">
          <ArrayInput source="couponTargets">
            <SimpleFormIterator inline>
              <CouponTargetInput />
              <CouponTargetIdInput />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
