import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  label: string;
  optionText: (option: any) => string;
  request: (q: string) => Promise<any[]>;
  onChange: (option: any) => void;
};

const AsyncAutoComplete: React.FC<Props> = ({
  label,
  request,
  optionText,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    request('').then((result) => {
      setOptions(result);
    });
  }, []);
  const search = useCallback(
    (text: string) => {
      if (loading) {
        return;
      }

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        setLoading(true);
        request(text)
          .then((_options) => {
            setOptions(_options);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 200);
    },
    [request, loading]
  );

  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        optionText(option) === optionText(value)
      }
      getOptionLabel={optionText}
      options={options}
      loading={loading}
      onChange={(_e, value) => {
        onChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => {
            search(e.target.value);
          }}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncAutoComplete;
