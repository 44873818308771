import {
  ArrayInput,
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import CampaignTypeInput from '../../components/CampaignTypeInput';
import ImageUploader from '../../components/ImageUploader';

const validateFieldData = (record: any) => {
  const errors: { [key: string]: string } = {};

  if (record.users.length < 1) {
    errors.users = '유저는 필수입니다.';
  }

  if (!record.type) {
    errors.type = '캠페인 유형은 필수입니다.';
  }

  if (!record.link) {
    errors.link = '링크는 필수입니다.';
  }

  if (!record.content) {
    errors.content = '내용은 필수입니다.';
  }

  return errors;
};

export const UserNotificationCreate = () => (
  <Create redirect="list">
    <SimpleForm validate={validateFieldData}>
      <ArrayInput source="users">
        <SimpleFormIterator>
          <ReferenceInput source="id" reference="users">
            <AutocompleteInput
              optionText={(user) => {
                return `[${user.id}][${user.name}]`;
              }}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <CampaignTypeInput source="type" />
      <ImageUploader source="thumbnailUrl" label="썸네일 URL" />
      <TextInput fullWidth source="link" label="링크 URL" />
      <TextInput fullWidth multiline source="content" label="내용" />
    </SimpleForm>
  </Create>
);
