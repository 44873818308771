import { useRecordContext } from 'react-admin';
import { getEnumsText, pointEventExecutorEnums } from '../utils/enum';

const PointEventExecutorTypeField = ({ source }) => {
  const record = useRecordContext();
  const executor = record[source];

  return <div>{getEnumsText(pointEventExecutorEnums)(executor)}</div>;
};

PointEventExecutorTypeField.defaultProps = { label: '지급자 유형' };

export default PointEventExecutorTypeField;
