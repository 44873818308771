import {
  AutocompleteInput,
  ReferenceInput,
  required,
  useSimpleFormIteratorItem,
} from 'react-admin';
import { useController } from 'react-hook-form';

const getFilter = (target: string) => {
  if (target === 'point-events') {
    return { identifier: 'COUPON' };
  }
  if (target === 'contents') {
    return { status: 'ACTIVE', publishedAt: 'NOT_NULL' };
  }
  if (target === 'serieses') {
    return { status: 'ACTIVE', isCompleted: true };
  }
  return {};
};

const CouponTargetIdInput = () => {
  const { index } = useSimpleFormIteratorItem();
  const { field } = useController({ name: `couponTargets.${index}.target` });

  const target = field.value?.replace(/_/g, '-');

  if (!target) {
    return <></>;
  }

  return (
    <ReferenceInput
      source={`couponTargets.${index}.targetId`}
      reference={target}
      filter={getFilter(target)}
    >
      <AutocompleteInput
        sx={{ minWidth: 500 }}
        source="title"
        optionText="title"
        label="제목"
        validate={required('쿠폰 타겟은 필수값입니다.')}
      />
    </ReferenceInput>
  );
};

export default CouponTargetIdInput;
