import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import HexColorInput from '../../components/HexColorInput';
import ImageUploader from '../../components/ImageUploader';
import StatusInput from '../../components/StatusInput';

export const BannerEdit = () => (
  <Edit>
    <SimpleForm>
      <StatusInput />
      <TextInput source="description" label="배너 설명" />
      <HexColorInput source="backgroundHexColor" label="배너 배경색(16진수)" />
      <TextInput source="link" label="배너 링크" />
      <NumberInput source="priority" label="배너 노출순서" />
      <ImageUploader source="bannerImageUrl" label="배너 이미지" width={300} />
      <TextInput multiline source="mainText" label="메인 텍스트" />
      <HexColorInput source="mainTextHexColor" label="메인 텍스트색(16진수)" />
      <TextInput multiline source="subText" label="서브 텍스트" />
      <HexColorInput source="subTextHexColor" label="서브 텍스트색(16진수)" />
    </SimpleForm>
  </Edit>
);
