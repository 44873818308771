import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { CouponUsageList } from './list';
import { CouponUsageShow } from './show';

const couponUsages = {
  name: 'coupon-usages',
  icon: PlaylistPlayIcon,
  list: CouponUsageList,
  show: CouponUsageShow,
  options: { label: '쿠폰 사용 내역', permission: 'admin' },
};
export default couponUsages;
