import { Datagrid, List, TextField, WrapperField } from 'react-admin';
import CampaignTypeField from '../../components/CampaignTypeField';
import DateTimeField from '../../components/DateTimeField';
import EllipsisTextField from '../../components/EllipsisTextField';
import NotificationTargetField from '../../components/NotificationTargetField';
import SizedImageField from '../../components/SizedImageField';

export const NotificationCampaignList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <CampaignTypeField source="type" />
      <SizedImageField width={100} source="thumbnailUrl" label="썸네일" />
      <TextField source="link" label="링크 URL" />
      <EllipsisTextField maxWidth={300} source="content" label="내용" />
      <TextField source="content" label="내용" />
      <TextField source="status" label="스케줄 상태" />
      <NotificationTargetField />
      <DateTimeField source="reservedAt" label="예약일시" />
      <WrapperField label="생성/수정">
        <DateTimeField source="createdAt" />
        <br />
        <DateTimeField source="updatedAt" />
      </WrapperField>
    </Datagrid>
  </List>
);
