import UpdateIcon from '@mui/icons-material/Update';
import { omit } from 'lodash';
import { useCallback } from 'react';
import {
  Button,
  ButtonProps,
  useListContext,
  useRecordContext,
} from 'react-admin';
import api from '../utils/api';

type Props = {
  resource: string;
  label: string;
  data: any;
  onSuccess?: () => void;
};

const BulkUpdateAtOnceButton: React.FC<Props & ButtonProps> = (props) => {
  const { resource, label, data, onSuccess } = props;
  const record = useRecordContext();
  const { selectedIds, onUnselectItems, refetch } = useListContext();
  const query = selectedIds.map((selectedId) => `id=${selectedId}`).join('&');
  const updateMany = useCallback(() => {
    api.put(`/${resource}?${query}`, data).then(() => {
      onUnselectItems();
      refetch();
      onSuccess?.();
    });
  }, [resource, selectedIds, record, data]);

  return (
    <Button
      startIcon={<UpdateIcon />}
      label={label}
      {...omit(props, ['resource', 'label', 'data', 'onSuccess'])}
      onClick={() => {
        updateMany();
      }}
    />
  );
};

export default BulkUpdateAtOnceButton;
