import { Card, CardContent } from '@mui/material';
import { Datagrid, List, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';

export const ExternalPageList = () => (
  <>
    <List exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="type" label="타입" />
        <TextField source="link" label="외부 링크 주소" />
        <DateTimeField source="createdAt" label="생성" />
        <DateTimeField source="updatedAt" label="수정" />
      </Datagrid>
    </List>
    <Card>
      <CardContent>
        <b>외부페이지 링크 사용방법</b>
        <p>
          외부 페이지 링크를등록하면 https://piper.so/ext/[타입] 에 페이지가
          연결됩니다.
          <br />
          ex) https://piper.so/ext/termsofservice
        </p>
        <p>
          외부 링크 주소는 항상 https:// 로 시작해야 합니다.
          <br /> (되도록 oopy 를 활용 해 주세요)
        </p>
      </CardContent>
    </Card>
  </>
);
