import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  FormTab,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useEditController,
} from 'react-admin';

export const UserEdit = () => {
  const { record } = useEditController();
  console.log('CONTEXT', record);
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="유저정보">
          <TextInput source="id" />
          <TextInput source="email" />
          <TextInput source="name" />
          <BooleanInput source="isWriter" />
          <TextInput source="lastLoginedAt" />
          <DateInput source="createdAt" aria-readonly />
          <DateInput source="updatedAt" aria-readonly />
          <DateInput source="deletedAt" />
        </FormTab>
        {record.author && (
          <FormTab label="저자정보">
            <TextInput source="author.legalName" label="저자명" />
            <TextInput source="author.summary" label="한줄소개" />
            <ArrayInput source="author.careers" label="경력">
              <SimpleFormIterator>
                <TextInput source="" />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="author.papers" label="저서">
              <SimpleFormIterator>
                <TextInput source="" />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="author.link" label="외부링크" />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};
