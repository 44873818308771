import { TextField, TextFieldProps } from 'react-admin';

type Props = {
  maxWidth: number;
};

const EllipsisTextField: React.FC<TextFieldProps & Props> = (props) => {
  return (
    <TextField
      {...props}
      sx={{
        whiteSpace: 'nowrap',
        maxWidth: props.maxWidth,
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
      }}
    />
  );
};

export default EllipsisTextField;
