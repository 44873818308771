import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  WrapperField,
} from 'react-admin';
import CampaignTypeField from '../../components/CampaignTypeField';
import DateTimeField from '../../components/DateTimeField';
import EllipsisTextField from '../../components/EllipsisTextField';
import SizedImageField from '../../components/SizedImageField';
import UserField from '../../components/UserField';

export const UserNotificationList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <UserField />
      </ReferenceField>
      <CampaignTypeField source="type" />
      <SizedImageField width={100} source="thumbnailUrl" label="썸네일" />
      <TextField source="link" label="링크 URL" />
      <EllipsisTextField source="content" label="내용" maxWidth={300} />
      <DateTimeField source="checkedAt" label="확인일시" />
      <WrapperField label="생성/수정">
        <DateTimeField source="createdAt" />
        <br />
        <DateTimeField source="updatedAt" />
      </WrapperField>
    </Datagrid>
  </List>
);
