import PersonIcon from '@mui/icons-material/Person';
import { UserCreate } from './create';
import { UserEdit } from './edit';
import { UserList } from './list';
import { UserShow } from './show';

const users = {
  name: 'users',
  icon: PersonIcon,
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  options: { label: '회원관리', permission: 'admin' },
};

export default users;
