import {
  CircularProgress,
  ModalProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { Button, RecordContextProvider, useRecordContext } from 'react-admin';
import { useApiCallback } from '../hooks/api.hooks';
import CenterModal from './CenterModal';

type Props = { payment: any } & Omit<ModalProps, 'children'>;

const PaymentCancelModal: React.FC<Props> = (props) => {
  const record = useRecordContext();
  const { payment, onClose } = props;
  const [cancelRequest, setCancelRequest] = useState<PaymentCancelRequest>({
    cancelPoint: record.chargedPoint?.balance,
  });
  const { load: cancelPayment, isLoading } = useApiCallback<
    boolean,
    PaymentCancelRequest
  >(`user-payments/${payment.id}/cancel`, (err) => {
    if (err) {
      alert(err.message);
      return;
    }

    alert('취소/환불이 완료되었습니다');
    onClose({}, 'escapeKeyDown');
  });

  function setCancelRequestValue(field: string, value: any) {
    setCancelRequest({
      ...cancelRequest,
      [field]: value,
    });
  }

  return (
    <CenterModal {...props} style={{ position: 'relative' }}>
      <RecordContextProvider value={cancelRequest}>
        <PaymentCancelTextField
          label="취소금액"
          field="amount"
          type="number"
          onValue={setCancelRequestValue}
          placeholder="빈칸은 전액취소"
        />
        <PaymentCancelTextField
          label="취소사유"
          field="reason"
          onValue={setCancelRequestValue}
          required
        />
        <PaymentCancelTextField
          label="회수 포인트"
          field="cancelPoint"
          type="number"
          onValue={setCancelRequestValue}
          required
        />
        <Button
          label="취소/환불"
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            cancelPayment(cancelRequest);
          }}
        />
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </RecordContextProvider>
    </CenterModal>
  );
};

export default PaymentCancelModal;

type PaymentCancelTextFieldProps = {
  field: string;
  onValue: (field: string, value: string) => void;
  required?: boolean;
} & TextFieldProps;

const PaymentCancelTextField: React.FC<PaymentCancelTextFieldProps> = (
  props
) => {
  const { field, onValue, required } = props;
  const cancelRequest = useRecordContext();
  const value = get(cancelRequest, field);

  return (
    <TextField
      {...props}
      sx={{ width: '100%', marginBottom: 2 }}
      value={value}
      onChange={(e) => {
        onValue(field, e.target.value);
      }}
      helperText={required && !value && '필수입력 필드입니다.'}
    />
  );
};

type PaymentCancelRequest = {
  reason?: string;
  amount?: number;
  cancelPoint?: number;
};
