import {
  AutocompleteInput,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  WrapperField,
} from 'react-admin';
import CouponTargetField from '../../components/CouponTargetField';
import CouponTargetResourceField from '../../components/CouponTargetResourceField';
import DateTimeField from '../../components/DateTimeField';
import UserField from '../../components/UserField';

const couponUsageFilter = [
  <ReferenceInput label="쿠폰 사용자" source="userId" reference="users">
    <AutocompleteInput
      source="q"
      label="쿠폰 사용자"
      optionText={(user) => `${user.id}_${user.name}(${user.email})`}
    />
  </ReferenceInput>,
  <ReferenceInput label="쿠폰" source="couponId" reference="coupons">
    <AutocompleteInput
      source="q"
      label="쿠폰"
      optionText={(coupon) => `${coupon.id}_${coupon.title}`}
    />
  </ReferenceInput>,
];
export const CouponUsageList = () => {
  return (
    <List exporter={false} filters={couponUsageFilter}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField label="쿠폰 사용자" source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <ReferenceField
          label="쿠폰"
          source="couponId"
          reference="coupons"
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <CouponTargetField label="타겟" />
        <CouponTargetResourceField />
        <WrapperField label="생성/수정">
          <DateTimeField source="createdAt" />
          <br />
          <DateTimeField source="updatedAt" />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
