import {
  BooleanField,
  Datagrid,
  EmailField,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import QuickFilter from '../../components/QuickFilter';

const userFilters = [
  <TextInput label="이름 / 이메일" source="q" alwaysOn />,
  <QuickFilter label="저자만" source="isWriter" defaultValue={true} />,
];

export const UserList = () => (
  <List filters={userFilters} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <BooleanField source="isWriter" />
      <DateTimeField source="lastLoginedAt" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
