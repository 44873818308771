import {
  Create,
  DateTimeInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import PointEventIdentifierTypeInput from '../../components/PointEventIdentifierTypeInput';
import PointEventRestrictionTypeInput from '../../components/PointEventRestrictionTypeInput';

const validateFieldData = (record) => {
  const errors: { [key: string]: string } = {};

  if (!record.identifier) {
    errors.identifier = '식별자 값은 필수입니다.';
  }

  if (!record.points) {
    errors.points = '회당 지급 파이 수 값은 필수입니다.';
  }

  return errors;
};

export const PointEventCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm validate={validateFieldData}>
        <TextInput source="title" label="이벤트 제목" />
        <PointEventIdentifierTypeInput />
        <PointEventRestrictionTypeInput />
        <NumberInput
          source="points"
          label="지급 파이 수"
          min={0}
          helperText="쿠폰등록시 1회 지급될 파이의 수입니다."
        />
        <DateTimeInput source="startAt" label="시작일" />
        <DateTimeInput source="expiredAt" label="만료일" />
      </SimpleForm>
    </Create>
  );
};
