import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';
import CancelStatusField from '../../components/CancelStatusField';
import CurrencyField from '../../components/CurrencyField';
import PaymentCardField from '../../components/PaymentCardField';
import ProgressStatusField from '../../components/ProgressStatusField';
import UserField from '../../components/UserField';

const userPaymentsFilters = [
  <TextInput resettable label="ID" source="id" />,
  <ReferenceInput label="회원" source="userId" reference="users">
    <AutocompleteInput
      source="q"
      label="회원명 / 이메일"
      optionText={(user) => `${user.id}_${user.name}(${user.email})`}
    />
  </ReferenceInput>,
];

export const UserPaymentList = () => (
  <List exporter={false} filters={userPaymentsFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <UserField />
      </ReferenceField>
      <TextField source="orderName" />
      <CurrencyField source="amount" />
      <TextField label="결제방식" source="method" />
      <ReferenceField
        label="간편결제 정보"
        source="paymentMethodId"
        reference="user-payment-methods"
        link="show"
      >
        <PaymentCardField source="method.card" />
      </ReferenceField>
      <ProgressStatusField source="status" />
      <CancelStatusField source="cancelStatus" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
