import { SelectInput, SelectInputProps } from 'react-admin';
import { couponTypeEnums, getEnumsChoices } from '../utils/enum';

const CouponTypeInput: React.FC<SelectInputProps> = () => {
  return (
    <SelectInput
      label="쿠폰타입(고정형/발급형)"
      source="type"
      choices={getEnumsChoices(couponTypeEnums)}
      required
    />
  );
};

export default CouponTypeInput;
