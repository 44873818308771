import { Datagrid, List, NumberField, TextField } from 'react-admin';
import DateTimeField from '../../components/DateTimeField';

export const TemplateList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField label="템플릿 이름" source="title" />
      <NumberField label="노출순서" source="priority" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
