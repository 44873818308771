import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import CategoryField from '../../components/CategoryField';
import DateTimeField from '../../components/DateTimeField';
import StatusField from '../../components/StatusField';

export const QuestionList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        label="카테고리"
        source="categoryId"
        reference="question-categories"
        link="show"
      >
        <CategoryField />
      </ReferenceField>
      <TextField label="질문" source="text" />
      <StatusField />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);
