import {
  FunctionField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import PieIcon from '../../components/PieIcon';
import PointPackagePromotionField from '../../components/PointPackagePromotionField';

export const PointPackageShow = () => (
  <Show actions={<DefaultShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <FunctionField
        label="아이콘"
        render={(record: any) => <PieIcon src={record.iconUrl} />}
      />
      <NumberField source="points" label="파이갯수" />
      <CurrencyField source="price" label="가격" />
      <NumberField source="discountRate" label="할인율" sortable={false} />
      <PointPackagePromotionField source="promotion" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
