import { useRecordContext } from 'react-admin';
import { getEnumsText, pointPackagePromotionEnums } from '../utils/enum';

const PointPackagePromotionField = ({ source }) => {
  const record = useRecordContext();
  const promotion = record[source];

  return <>{getEnumsText(pointPackagePromotionEnums)(promotion)}</>;
};

PointPackagePromotionField.defaultProps = { label: '프로모션명' };

export default PointPackagePromotionField;
