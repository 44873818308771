import { Labeled, useRecordContext } from 'react-admin';

type Props = {
  source: string;
  label: string;
};

const HexColorField: React.FC<Props> = ({ source, label }) => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Labeled label={label}>
      <span
        style={{
          display: 'inline-block',
          width: 40,
          height: 20,
          backgroundColor: `${record[source]}`,
        }}
      >
        {' '}
      </span>
    </Labeled>
  );
};

export default HexColorField;
