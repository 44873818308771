import {
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import PointEventIdentifierTypeField from '../../components/PointEventIdentifierTypeField';
import PointEventRestrictionTypeField from '../../components/PointEventRestrictionTypeField';

export const PointEventShow = () => (
  <Show actions={<DefaultShowActions />}>
    <TabbedShowLayout>
      <Tab label="이벤트 기본정보">
        <TextField source="id" />
        <TextField source="title" label="이벤트 제목" />
        <PointEventIdentifierTypeField source="identifier" />
        <PointEventRestrictionTypeField source="restriction" />
        <NumberField source="points" label="지급 파이 수" />
        <DateTimeField source="startAt" label="시작일" />
        <DateTimeField source="expiredAt" label="만료일" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
