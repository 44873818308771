import LinkIcon from '@mui/icons-material/Link';
import { ExternalPageCreate } from './create';
import { ExternalPageEdit } from './edit';
import { ExternalPageList } from './list';

const externalPages = {
  name: 'external-pages',
  icon: LinkIcon,
  list: ExternalPageList,
  edit: ExternalPageEdit,
  create: ExternalPageCreate,
  options: { label: '외부 페이지 관리', permission: 'admin' },
};

export default externalPages;
