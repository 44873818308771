import { useEffect } from 'react';
import { SelectInput } from 'react-admin';
import { useController } from 'react-hook-form';
import { getEnumsChoices, pointEventRestrictionEnums } from '../utils/enum';

const PointEventRestrictionTypeInput = () => {
  const { field: identifierField } = useController({ name: 'identifier' });
  const { field } = useController({ name: 'restriction' });

  useEffect(() => {
    if (identifierField.value === 'COUPON') {
      field.onChange({
        target: { value: '' },
      });
    }
  }, [identifierField.value]);

  return (
    <SelectInput
      label="이벤트 지급 설정"
      source="restriction"
      choices={getEnumsChoices(pointEventRestrictionEnums)}
      defaultValue={''}
      disabled={identifierField.value === 'COUPON'}
    />
  );
};

export default PointEventRestrictionTypeInput;
