import { get } from 'lodash';
import { useRecordContext, WrapperField, WrapperFieldProps } from 'react-admin';
import { getSizedImageUrl } from '../utils/image';
import LoadableImage from './LoadableImage';

type Props = {
  width?: number;
  height?: number;
};
const SizedImageField: React.FC<Omit<WrapperFieldProps, 'children'> & Props> = (
  props
) => {
  const record = useRecordContext();
  const src = get(record, props.source);

  if (!src) {
    return null;
  }

  return (
    <WrapperField {...props}>
      <LoadableImage
        src={getSizedImageUrl(src, props.width, props.height)}
        style={{ width: props.width }}
      />
    </WrapperField>
  );
};

export default SizedImageField;
