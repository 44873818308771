import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { CategoryCreate } from './create';
import { CategoryEdit } from './edit';
import { CategoryList } from './list';
import { CategoryShow } from './show';

const categories = {
  name: 'categories',
  icon: ViewModuleIcon,
  list: CategoryList,
  show: CategoryShow,
  edit: CategoryEdit,
  create: CategoryCreate,
  options: { label: '카테고리', permission: 'admin' },
};

export default categories;
