import {
  Datagrid,
  List,
  NumberField,
  TextField,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import HexColorField from '../../components/HexColorField';
import SizedImageField from '../../components/SizedImageField';
import StatusField from '../../components/StatusField';

export const BannerList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField label="배너 설명" source="description" />
      <HexColorField label="배너 배경색" source="backgroundHexColor" />
      <SizedImageField
        width={100}
        height={100}
        label="배너 이미지"
        source="bannerImageUrl"
      />
      <TextField label="배너 링크" source="link" />
      <NumberField label="노출순서" source="priority" />\
      <StatusField label="공개여부" />
      <TextField label="메인 텍스트" source="mainText" />
      <HexColorField label="메인 텍스트색(16진수)" source="mainTextHexColor" />
      <TextField label="서브 텍스트" source="subText" />
      <HexColorField label="서브 텍스트색(16진수)" source="subTextHexColor" />
      <WrapperField label="생성/수정">
        <DateTimeField source="createdAt" />
        <br />
        <DateTimeField source="updatedAt" />
      </WrapperField>
    </Datagrid>
  </List>
);
