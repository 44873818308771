import { useRecordContext } from 'react-admin';

const CategoryField = () => {
  const category = useRecordContext();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          display: 'inline-block',
          height: 15,
          width: 15,
          background: `${category.hexColor}`,
        }}
      />
      <span>{category.title}</span>
    </div>
  );
};

export default CategoryField;
