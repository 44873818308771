import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import PieIconInput from '../../components/PieIconInput';
import PointPackagePromotionInput from '../../components/PointPackagePromotionInput';

export const PointPackageEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <PieIconInput />
      <NumberInput source="points" label="파이갯수" />
      <NumberInput source="price" label="가격" />
      <PointPackagePromotionInput source="promotion" label="프로모션명" />
    </SimpleForm>
  </Edit>
);
