import { AuthProvider } from 'react-admin';
import api from './api';
import { getToken, removeToken, setToken } from './token';

type LoginBody = {
  email: string;
  password: string;
};

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const token = await api.post<LoginBody, string>('auth/login', {
      email: username,
      password: password,
    });
    setToken(token);
    return;
  },
  logout: async () => {
    removeToken();
  },
  checkAuth: () => {
    if (!getToken()) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: (error) => {
    console.log('Check Error', error);
    if (error.status === 401) {
      removeToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: async () => {
    const user = await api.get<{ id: string; name: string; email: string }>(
      'auth/identity'
    );
    return {
      ...user,
      fullName: user.email,
    };
  },
  getPermissions: async () => {
    const user = await api.get<{ permissions: string[] }>('auth/identity');
    return user.permissions;
  },
};

export default authProvider;
