import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import {
  LinearProgress,
  Menu,
  MenuItemLink,
  MenuProps,
  ResourceProps,
  usePermissions,
  useSidebarState,
} from 'react-admin';
import admins from '../resources/admins';
import banners from '../resources/banners';
import categories from '../resources/categories';
import contentRestrictions from '../resources/contentRestrictions';
import contents from '../resources/contents';
import coupons from '../resources/coupons';
import couponUsages from '../resources/couponUsages';
import externalPages from '../resources/externalPages';
import notificationCampaigns from '../resources/notificationCampaigns';
import pointEventExecutions from '../resources/pointEventExecutions';
import pointEvents from '../resources/pointEvents';
import pointHistories from '../resources/pointHistories';
import pointPackages from '../resources/pointPacakges';
import questionCategories from '../resources/questionCategories';
import questions from '../resources/questions';
import replies from '../resources/replies';
import serieses from '../resources/serieses';
import settlementRequests from '../resources/settlement-requests';
import settlementResults from '../resources/settlement-results';
import templates from '../resources/templates';
import userNotifications from '../resources/userNotifications';
import userPayments from '../resources/userPayments';
import users from '../resources/users';

type MenuItemType = {
  label: string;
  subMenus: ResourceProps[];
};

const menus: MenuItemType[] = [
  {
    label: '메인 관리',
    subMenus: [banners],
  },
  {
    label: '알림 관리',
    subMenus: [notificationCampaigns, userNotifications],
  },
  {
    label: '회원 관리',
    subMenus: [users, admins],
  },
  {
    label: '파이퍼 관리',
    subMenus: [categories, serieses, contents, templates, contentRestrictions],
  },
  {
    label: '댓글 관리',
    subMenus: [replies],
  },
  {
    label: '질문 관리',
    subMenus: [questions, questionCategories],
  },
  {
    label: '이벤트 관리',
    subMenus: [pointEvents, pointEventExecutions],
  },
  {
    label: '쿠폰 관리',
    subMenus: [coupons, couponUsages],
  },
  {
    label: '파이 관리',
    subMenus: [pointHistories, pointPackages],
  },
  { label: '결제 관리', subMenus: [userPayments] },
  { label: '정산 관리', subMenus: [settlementRequests, settlementResults] },
  { label: '기타 관리', subMenus: [externalPages] },
];

const CustomMenu = (props: MenuProps) => {
  return (
    <Menu {...props}>
      <MenuItemLink
        to="/"
        primaryText="대쉬보드"
        leftIcon={<DashboardIcon />}
      />
      {menus.map((menu) => {
        return <ExpandableMenu key={menu.label} menu={menu} />;
      })}
    </Menu>
  );
};

export default CustomMenu;

const ExpandableMenu: React.FC<{
  menu: MenuItemType;
}> = ({ menu }) => {
  const Icon = menu.subMenus[0].icon;
  const [isOpen, setOpen] = useState(true);
  const [isSidebarOpen] = useSidebarState();
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return <LinearProgress />;
  }

  const filteredMenus = menu.subMenus.filter((subMenu) => {
    const permission = subMenu.options?.permission as string;
    return !permission || permissions.includes(permission);
  });

  if (filteredMenus.length < 1) {
    return null;
  }

  const icon = isSidebarOpen ? (
    <Icon />
  ) : (
    <Tooltip title={menu.label} placement="right">
      <Icon />
    </Tooltip>
  );

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <>
          <ListItemIcon>{isOpen ? <ExpandMoreIcon /> : icon}</ListItemIcon>
          {menu.label}
        </>
      </MenuItem>
      <Collapse in={isOpen} style={isSidebarOpen && { paddingLeft: 10 }}>
        {filteredMenus.map((subMenu) => {
          const SubMenuIcon = subMenu.icon;

          return (
            <MenuItemLink
              key={subMenu.name}
              to={`/${subMenu.name}`}
              primaryText={subMenu.options.label}
              leftIcon={<SubMenuIcon />}
            />
          );
        })}
      </Collapse>
    </>
  );
};
