import {
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  WrapperField,
} from 'react-admin';
import CouponTargetField from '../../components/CouponTargetField';
import CouponTargetResourceField from '../../components/CouponTargetResourceField';
import DateTimeField from '../../components/DateTimeField';
import UserField from '../../components/UserField';

export const CouponUsageShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField label="쿠폰 사용자" source="userId" reference="users">
        <UserField />
      </ReferenceField>
      <ReferenceField label="쿠폰" source="couponId" reference="coupons">
        <TextField source="title" />
      </ReferenceField>
      <CouponTargetField label="타겟" />
      <CouponTargetResourceField />
      <WrapperField label="생성/수정">
        <DateTimeField source="createdAt" />
        <br />
        <DateTimeField source="updatedAt" />
      </WrapperField>
    </SimpleShowLayout>
  </Show>
);
