import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  ButtonProps,
  TextField,
  useListContext,
  useNotify,
  useRecordContext,
  useUpdate,
  WrapperField,
  WrapperFieldProps,
} from 'react-admin';

type Props = {
  resource: string;
};

const EditablePriorityField: React.FC<
  Omit<WrapperFieldProps, 'children'> & Props
> = (props) => {
  return (
    <WrapperField {...props}>
      <TextField source="priority" label="순서" />
      <PriorityChanger direction="up" resource={props.resource} />
      <PriorityChanger direction="down" resource={props.resource} />
    </WrapperField>
  );
};

export default EditablePriorityField;

type PriorityChangerProps = {
  direction: 'up' | 'down';
  resource: string;
};

const PriorityChanger: React.FC<PriorityChangerProps & ButtonProps> = (
  props
) => {
  const notify = useNotify();
  const { data, refetch } = useListContext();
  const record = useRecordContext();

  const diff = props.direction === 'up' ? -1 : 1;
  const [updatePriority] = useUpdate(
    props.resource,
    {
      id: record.id,
      data: { priority: record.priority + diff },
      previousData: record,
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (e: any) => {
        notify(e.message);
      },
    }
  );

  const icon = props.direction === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />;
  const disabled =
    props.direction === 'up'
      ? record.priority === 1
      : data.length === record.priority;

  return (
    <Button
      {...props}
      endIcon={icon}
      sx={{ minWidth: 'auto' }}
      onClick={() => {
        updatePriority();
      }}
      disabled={disabled}
    />
  );
};
