import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import CategoryField from '../../components/CategoryField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import StatusField from '../../components/StatusField';

export const QuestionShow = () => (
  <Show actions={<DefaultShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        label="카테고리"
        source="categoryId"
        reference="question-categories"
        link={'show'}
      >
        <CategoryField />
      </ReferenceField>
      <TextField label="질문" source="text" />
      <StatusField />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
