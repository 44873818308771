import { Button, Pagination } from '@mui/material';
import {
  ArrayField,
  ChipField,
  Datagrid,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import AuthorField from '../../components/AuthorField';
import BlockContentsField from '../../components/BlockContentsField';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import SeriesList from '../../components/SeriesesField';
import SizedImageField from '../../components/SizedImageField';
import { getServiceUrl } from '../../utils/env';

const LeftActions = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <a href={`${getServiceUrl()}/contents/${record.id}/edit`} target="_blank">
      <Button variant="contained">콘텐츠 편집하기</Button>
    </a>
  );
};

export const ContentShow = () => {
  const { record } = useShowController();

  return (
    <Show
      actions={
        <DefaultShowActions leftActions={<LeftActions record={record} />} />
      }
    >
      <TabbedShowLayout>
        <Tab label="정보">
          <TextField source="id" />
          <ReferenceArrayField
            source="categoryIds"
            reference="categories"
            label="카테고리"
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            source="seriesIds"
            reference="serieses"
            label="시리즈"
          >
            <SeriesList />
          </ReferenceArrayField>
          <TextField label="제목" source="title" />
          <ReferenceField label="저자" source="authorId" reference="authors">
            <AuthorField />
          </ReferenceField>
          <SizedImageField
            width={400}
            height={300}
            label="메인 이미지"
            source="thumbnail"
          />
          <CurrencyField source="price" currency="파이" />
          <TextField
            label="선택된 템플릿 제목"
            source="contentSelectedTemplate.selectedTemplate.title"
          />
          <NumberField label="수수료율 (%)" source="platformChargePercentage" />
          <DateTimeField source="createdAt" />
          <DateTimeField source="updatedAt" />
        </Tab>
        <Tab label="stored blocks">
          <ArrayField source="blocks">
            <Datagrid isRowSelectable={() => false}>
              <TextField label="블록 id" source="id" />
              <TextField label="블록 상태" source="status" />
              <TextField
                label="질문 카테고리"
                source="question.category.title"
              />
              <TextField label="질문" source="question.text" />
              <BlockContentsField />
              <DateTimeField source="createdAt" />
              <DateTimeField source="updatedAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="발행">
          <ReferenceManyField
            reference="published-contents"
            target="contentId"
            source="id"
            filter={{ withDeleted: true }}
          >
            <>
              <Datagrid isRowSelectable={() => false} rowClick="show">
                <TextField source="title" />
                <NumberField source="price" />
                <DateTimeField source="createdAt" />
              </Datagrid>
              <Pagination />
            </>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
