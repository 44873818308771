import { CircularProgress, ModalProps } from '@mui/material';
import { useCallback } from 'react';
import { Button, useRedirect } from 'react-admin';
import { useApiCallback, useApiEffect } from '../hooks/api.hooks';
import api from '../utils/api';
import CenterModal from './CenterModal';

type Props = {
  pointHistory: any;
} & Omit<ModalProps, 'children'>;

type PointHistoryCancelStatus =
  | { cancelable: true }
  | { cancelable: false; reason: string };

const RefundModal: React.FC<Props> = (props) => {
  const { pointHistory, onClose } = props;
  const redirect = useRedirect();

  const { data: cancelStatus } = useApiEffect<PointHistoryCancelStatus>(
    `point-histories/${pointHistory.id}/cancel-status`
  );

  const redirectPayment = useCallback(
    (purchaseKey: string) => {
      api.get(`/user-payments?purchaseKey=${purchaseKey}`).then((data) => {
        const [payment] = data;
        if (!payment) {
          alert('결제정보를 찾을 수 없습니다.');
          return;
        }

        redirect('show', 'user-payments', payment.id);
      });
    },
    [pointHistory]
  );

  const { load: refundChallenge, isLoading } = useApiCallback<boolean, {}>(
    `point-histories/${pointHistory.id}/cancel`,
    (err) => {
      if (err) {
        alert(err.message);
        return;
      }

      alert('취소/환불이 완료되었습니다');
      onClose({}, 'escapeKeyDown');
    }
  );

  return (
    <CenterModal {...props} style={{ position: 'relative' }}>
      {cancelStatus && (
        <div>
          취소내역
          <br />
          {cancelStatus.cancelable === true ? '' : cancelStatus.reason}
          <br />
          정말로 취소 하시겠습니까?
        </div>
      )}
      <Button
        label="취소/환불"
        variant="contained"
        fullWidth
        size="large"
        onClick={() => {
          if (pointHistory.ref === 'point_package_purchases') {
            const purchaseKey = [
              'PointPackagePurchase',
              pointHistory.refId,
            ].join('_');
            return redirectPayment(purchaseKey);
          }
          return refundChallenge({});
        }}
      />
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </CenterModal>
  );
};

export default RefundModal;
