import { Edit, SimpleForm, TextInput } from 'react-admin';
import HexColorInput from '../../components/HexColorInput';

export const QuestionCategoryEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="title" />
      <HexColorInput source="hexColor" label="색상" />
    </SimpleForm>
  </Edit>
);
