import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import UserField from '../../components/UserField';

export const AdminList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <UserField />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
