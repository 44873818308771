import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';
import { couponTypeEnums, getEnumsText } from '../utils/enum';

const CouponTypeField = ({ source = 'type', label }) => {
  const record = useRecordContext();
  const type = get(record, source);

  return (
    <Labeled label={label}>
      <div>{getEnumsText(couponTypeEnums)(type)}</div>
    </Labeled>
  );
};

CouponTypeField.defaultProps = { label: '쿠폰 타입' };

export default CouponTypeField;
