import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { EditGuesser, ShowGuesser } from 'react-admin';
import { AdminCreate } from './create';
import { AdminList } from './list';

const contentRestrictions = {
  name: 'admins',
  icon: SupervisorAccountIcon,
  list: AdminList,
  show: ShowGuesser,
  edit: EditGuesser,
  create: AdminCreate,
  options: { label: '관리자 관리', permission: 'admin' },
};

export default contentRestrictions;
