import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="name" />
      <BooleanInput source="isWriter" />
    </SimpleForm>
  </Create>
);
