import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Button, useCreate, useRecordContext, useRefresh } from 'react-admin';
import api from '../utils/api';
import AsyncAutoComplete from './AsyncAutoComplete';
import CenterModal from './CenterModal';

type Props = {
  relation: string;
  target: string;
  label: string;
  open: boolean;
  onClose: () => void;
  filter?: { [key: string]: any };
};

const ManyToManyAddModal: React.FC<Props> = ({
  relation,
  target,
  label,
  open,
  onClose,
  filter = {},
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [add] = useCreate();
  const [targetId, setTargetId] = useState('');
  const [baseResource, targetResource] = relation.split('-');

  return (
    <CenterModal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <AsyncAutoComplete
        label={`등록할 ${label}`}
        request={(text) => {
          return api.get(`/${targetResource}`, {
            q: text,
            ...filter,
          });
        }}
        optionText={({ id, title }) => `${title} (id: ${id})`}
        onChange={(option) => setTargetId(option.id)}
      />

      <Button
        sx={{ marginTop: 4, color: 'white' }}
        variant="contained"
        size="large"
        fullWidth
        label="추가하기"
        startIcon={<AddIcon />}
        onClick={async () => {
          add(
            relation,
            {
              data: {
                [`${baseResource}Id`]: record.id,
                [`${target}Id`]: targetId,
              },
            },
            {
              onSuccess: () => {
                refresh();
              },
            }
          );
          onClose();
        }}
      />
    </CenterModal>
  );
};

export default ManyToManyAddModal;
