import api from '../utils/api';

export function useImageUpload(param: {
  onUploadStart?: () => void;
  onUploadEnd: (url: string) => void;
}) {
  async function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];
    const form = new FormData();
    form.append('file', file);
    param.onUploadStart?.();
    const res = await api.post<FormData, string>('/images', form);
    param.onUploadEnd(res);
  }
  return handleFile;
}
