import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { NotificationCampaignCreate } from './create';
import { NotificationCampaignEdit } from './edit';
import { NotificationCampaignList } from './list';
import { NotificationCampaignShow } from './show';

const notificationCampaigns = {
  name: 'notification-campaigns',
  icon: NotificationAddIcon,
  list: NotificationCampaignList,
  edit: NotificationCampaignEdit,
  show: NotificationCampaignShow,
  create: NotificationCampaignCreate,
  options: { label: '캠페인 관리', permission: 'admin' },
};

export default notificationCampaigns;
