import {
  AutocompleteInput,
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import EventExecutorTypeField from '../../components/PointEventExecutorTypeField';
import PointEventField from '../../components/PointEventField';
import UserField from '../../components/UserField';
import { formatUser } from '../../utils/format';

const PointEventExecutionFilters = [
  <ReferenceInput label="이벤트" source="pointEventId" reference="point-events">
    <AutocompleteInput
      source="q"
      label="이벤트 명"
      optionText={(pointEvent) => `${pointEvent.id}_${pointEvent.title}`}
    />
  </ReferenceInput>,
  <ReferenceInput source="userId" reference="users" label="지급받은 유저">
    <AutocompleteInput
      sx={{ minWidth: 300 }}
      optionText={(user) => formatUser(user)}
      label="지급받은 유저 이름_이메일"
    />
  </ReferenceInput>,
];

export const PointEventExecutionList = () => {
  return (
    <List exporter={false} filters={PointEventExecutionFilters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField
          source="pointEventId"
          reference="point-events"
          link="show"
        >
          <PointEventField />
        </ReferenceField>
        <ReferenceField label="지급받은 유저" source="userId" reference="users">
          <UserField />
        </ReferenceField>
        <EventExecutorTypeField source="executor" />
        <NumberField source="points" label="지급 파이" />
        <WrapperField label="생성/수정">
          <DateTimeField source="createdAt" />
          <br />
          <DateTimeField source="updatedAt" />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
