import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { Labeled } from 'react-admin';
import { useController } from 'react-hook-form';
import { useImageUpload } from '../hooks/upload.hooks';
import { getSizedImageUrl } from '../utils/image';

type Props = {
  source: string;
  label: string;
  width?: number;
  height?: number;
};

const ImageUploader: React.FC<Props> = ({ source, label, width, height }) => {
  const { field } = useController({ name: source });
  const [isLoading, setLoading] = useState(false);
  const handleFile = useImageUpload({
    onUploadStart: () => {
      setLoading(true);
    },
    onUploadEnd: (url) => {
      field.onChange({ target: { value: url } });
    },
  });

  return (
    <Labeled label={label}>
      <>
        {isLoading && <CircularProgress />}
        {field.value && (
          <img
            src={getSizedImageUrl(field.value, width * 2, height * 2)}
            onLoad={() => {
              setLoading(false);
            }}
          />
        )}
        <input type="file" onChange={handleFile} />
        <input
          {...field}
          value={field.value || ''}
          type="hidden"
          name={source}
        />
      </>
    </Labeled>
  );
};

export default ImageUploader;
