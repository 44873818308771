import { get } from 'lodash';
import {
  LinkToType,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';

const CouponTargetResourceField: React.FC<{
  label?: string;
  link?: LinkToType;
}> = ({ link }) => {
  const record = useRecordContext();
  const target = get(record, 'target');

  if (target === 'point_events') {
    return (
      <ReferenceField
        label="포인트"
        source="targetId"
        reference="point-events"
        link={link}
      >
        <>
          <NumberField source="id" />_
          <TextField source="title" />
          <br />
          충전 파이:
          <NumberField source="points" />
        </>
      </ReferenceField>
    );
  }

  if (target === 'contents') {
    return (
      <ReferenceField
        label="컨텐츠"
        source="targetId"
        reference="contents"
        link={link}
      >
        <>
          <NumberField source="id" />_
          <TextField source="title" />
        </>
      </ReferenceField>
    );
  }

  if (target === 'serieses') {
    return (
      <ReferenceField
        label="시리즈"
        source="targetId"
        reference="serieses"
        link={link}
      >
        <>
          <NumberField source="id" />_
          <TextField source="title" />
        </>
      </ReferenceField>
    );
  }

  throw new Error(`UnknownTarget|${target}`);
};

CouponTargetResourceField.defaultProps = { label: '타겟 리소스' };

export default CouponTargetResourceField;
