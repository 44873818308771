import RedeemIcon from '@mui/icons-material/Redeem';
import { PointPackageCreate } from './create';
import { PointPackageEdit } from './edit';
import { PointPackageList } from './list';
import { PointPackageShow } from './show';

const pointPackages = {
  name: 'point-packages',
  icon: RedeemIcon,
  list: PointPackageList,
  show: PointPackageShow,
  edit: PointPackageEdit,
  create: PointPackageCreate,
  options: { label: '파이묶음 관리', permission: 'admin' },
};

export default pointPackages;
