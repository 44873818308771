import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

type Props = {
  source: string;
  label: string;
};

const ChangedPointField: React.FC<Props> = ({ source }) => {
  const record = useRecordContext();
  const point = Number(get(record, source));
  function getColor() {
    if (point > 0) {
      return 'green';
    }
    if (point < 0) {
      return 'red';
    }
    return 'inherit';
  }
  return (
    <span
      style={{
        color: getColor(),
      }}
    >
      {point > 0 && '+'}
      {point}
    </span>
  );
};

export default ChangedPointField;
