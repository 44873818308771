import { get } from 'lodash';
import { Labeled, useRecordContext } from 'react-admin';
import { getEnumsText, progressStatusEnums } from '../utils/enum';

type Props = {
  source: string;
  label?: string;
};

const ProgressStatusField: React.FC<Props> = ({ source, label }) => {
  const record = useRecordContext();
  const status = get(record, source);
  const getText = getEnumsText(progressStatusEnums);

  return (
    <Labeled label={label || source}>
      <div
        style={{
          maxWidth: 50,
          backgroundColor: getFieldColor(status),
          padding: '2px 4px',
          textAlign: 'center',
          color: 'white',
        }}
      >
        {getText(status)}
      </div>
    </Labeled>
  );
};

export default ProgressStatusField;

function getFieldColor(status: string) {
  switch (status) {
    case 'READY':
      return 'yellow';
    case 'SUCCESS':
      return 'green';
    case 'FAILED':
      return 'red';
    default:
      return 'grey';
  }
}
