import { useRecordContext } from 'react-admin';
import { getEnumsText, pointEventIdentifierEnums } from '../utils/enum';

const PointEventIdentifierTypeField = ({ source }) => {
  const record = useRecordContext();
  const identifier = record[source];

  return <div>{getEnumsText(pointEventIdentifierEnums)(identifier)}</div>;
};

PointEventIdentifierTypeField.defaultProps = { label: '이벤트 식별' };

export default PointEventIdentifierTypeField;
