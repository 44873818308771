import { ReferenceField, TextField, useRecordContext } from 'react-admin';
import ChangedPointField from './ChangedPointField';
import DateTimeField from './DateTimeField';
import PointTransactionList from './PointTransactionList';

const UserPointReference = () => {
  const record = useRecordContext();

  return (
    <PointTransactionList filter={{ userPointId: record.id }}>
      <TextField source="id" label="트랜잭션 id" />
      <ReferenceField
        source="pointHistoryId"
        reference="point-histories"
        label="충전/사용 내역"
        link={false}
      >
        <TextField source="reason" />
      </ReferenceField>
      <ChangedPointField source="point" label="충전/사용 파이" />
      <DateTimeField source="createdAt" label="트랜잭션 일자" />
    </PointTransactionList>
  );
};

export default UserPointReference;
