import { Button } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  EmailField,
  Link,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useShowController,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import DefaultShowActions from '../../components/DefaultShowActions';
import StringListField from '../../components/StringListField';
import UserPointReference from '../../components/UserPointReference';
import UserTotalPointField from '../../components/UserTotalPointField';

const LeftActions = ({ record }) => {
  return (
    <>
      <Link
        style={{ marginLeft: 10 }}
        color="secondary"
        to={{
          pathname: '/point-histories',
          search: `filter=${JSON.stringify({
            userId: record?.id || 0,
          })}`,
        }}
      >
        <Button variant="contained">파이 충전/사용 내역 보기</Button>
      </Link>
    </>
  );
};

export const UserShow = () => {
  const { record } = useShowController();

  return (
    <Show
      actions={
        <DefaultShowActions
          deletable={false}
          leftActions={<LeftActions record={record} />}
        />
      }
    >
      <TabbedShowLayout>
        <Tab label="유저정보">
          <TextField source="id" />
          <EmailField source="email" label="이메일" />
          <TextField source="name" label="이름 (닉네임)" />
          <BooleanField source="isWriter" label="저자여부" />
          <DateTimeField source="createdAt" />
          <DateTimeField source="updatedAt" />
        </Tab>
        {record?.isWriter && (
          <Tab label="저자정보">
            <TextField source="author.id" />
            <TextField source="author.legalName" label="저자명" />
            <TextField source="author.summary" label="한줄소개" />
            <StringListField source="author.careers" label="경력" />
            <StringListField source="author.papers" label="저서" />
            <UrlField source="author.link" label="링크" />
          </Tab>
        )}
        <Tab label="파이잔액">
          <UserTotalPointField />
          <ReferenceManyField
            label="파이 충전/잔액 목록"
            reference="user-points"
            target="userId"
            perPage={10}
          >
            <>
              <Datagrid
                expandSingle
                rowClick="expand"
                expand={<UserPointReference />}
                isRowExpandable={() => true}
                isRowSelectable={() => false}
              >
                <TextField source="id" />
                <WrapperField label="잔액/충전">
                  <NumberField source="balance" label="잔액" />
                  &nbsp;/&nbsp;
                  <NumberField source="income" label="충전" />
                </WrapperField>
                <DateTimeField source="expiredAt" label="만료일" />
                <WrapperField label="생성/최종사용">
                  <DateTimeField source="createdAt" label="생성일" />
                  <br />
                  <DateTimeField source="updatedAt" label="최종 사용일" />
                </WrapperField>
              </Datagrid>
              <Pagination />
            </>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
