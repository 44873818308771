import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  FunctionField,
  RecordContextProvider,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  useRecordContext,
  useShowController,
} from 'react-admin';
import CancelStatusField from '../../components/CancelStatusField';
import CurrencyField from '../../components/CurrencyField';
import DateTimeField from '../../components/DateTimeField';
import PaymentCancelModal from '../../components/PaymentCancelModal';
import PaymentCardField from '../../components/PaymentCardField';
import ProgressStatusField from '../../components/ProgressStatusField';
import UserField from '../../components/UserField';
import { useApiEffect } from '../../hooks/api.hooks';

const PaymentActions = () => {
  const payment = useRecordContext();
  const [open, setOpen] = useState(false);
  return (
    <TopToolbar>
      <Button
        startIcon={<CancelIcon />}
        size="large"
        variant="contained"
        label="결제취소"
        onClick={() => {
          setOpen(true);
        }}
      />
      {payment && (
        <PaymentCancelModal
          payment={payment}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </TopToolbar>
  );
};

export const UserPaymentShow = () => {
  return (
    <Show actions={<PaymentActions />}>
      <TabbedShowLayout>
        <Tab label="기본정보">
          <TextField source="id" />
          <ReferenceField source="userId" reference="users" link="show">
            <UserField />
          </ReferenceField>
          <TextField source="orderName" />
          <CurrencyField source="amount" />
          <TextField source="purchaseKey" />
          <FunctionField
            label="충전 포인트"
            render={({ chargedPoint }) => {
              return (
                <span>
                  충전: {chargedPoint?.income} | 잔여: {chargedPoint?.balance}
                </span>
              );
            }}
          />
          <ReferenceField
            source="paymentMethodId"
            reference="user-payment-methods"
          >
            <PaymentCardField source="method.card" />
          </ReferenceField>
          <ProgressStatusField source="status" />
          <CancelStatusField source="cancelStatus" />
          <TextField source="paymentKey" />
          <UrlField source="receiptUrl" />
          <DateTimeField source="createdAt" />
          <DateTimeField source="updatedAt" />
        </Tab>
        <Tab label="결제 상세정보" path="payment-detail">
          <UserPaymentDetailField>
            <TextField source="mId" label="결제 상세 ID" />
            <TextField source="transactionKey" label="결제 트랜젝션" />
            <DateTimeField source="requestAt" label="결제요청 일자" />
            <DateTimeField source="approvedAt" label="결제승인 일자" />
            <CurrencyField source="totalAmount" label="결제 총금액" />
            <CurrencyField source="balanceAmount" label="환불가능 금액" />
            <CurrencyField source="suppliedAmount" label="공급가액" />
            <CurrencyField source="vat" label="부가세" />
            <ArrayField source="cancels">
              <Datagrid empty={<div>취소내역이 없습니다.</div>}>
                <TextField source="transactionKey" label="취소 트랜젝션" />
                <CurrencyField source="cancelAmount" label="취소금액" />
                <TextField source="cancelReason" label="취소사유" />
                <DateTimeField source="canceledAt" label="취소 일자" />
              </Datagrid>
            </ArrayField>
          </UserPaymentDetailField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const UserPaymentDetailField: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { record } = useShowController();
  const { data, isLoading, error } = useApiEffect<any>(
    `/user-payments/${record.id}/payment-detail`
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <RecordContextProvider value={data}>
      <SimpleShowLayout>{children}</SimpleShowLayout>
    </RecordContextProvider>
  );
};
