import {
  Datagrid,
  List,
  NumberField,
  TextField,
  TextInput,
  WrapperField,
} from 'react-admin';
import DateTimeField from '../../components/DateTimeField';
import PointEventIdentifierTypeField from '../../components/PointEventIdentifierTypeField';
import PointEventRestrictionTypeField from '../../components/PointEventRestrictionTypeField';
import QuickFilter from '../../components/QuickFilter';

const pointEventFilters = [
  <TextInput label="제목" source="q" alwaysOn />,
  <QuickFilter label="쿠폰" source="identifier" defaultValue={'COUPON'} />,
];

export const PointEventList = () => {
  return (
    <List exporter={false} filters={pointEventFilters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="title" label="이벤트 제목" />
        <PointEventIdentifierTypeField source="identifier" />
        <PointEventRestrictionTypeField source="restriction" />
        <NumberField source="points" label="지급 파이 수" />
        <NumberField source="executionCount" label="실행된 이벤트 수" />
        <DateTimeField source="startAt" label="시작일" />
        <DateTimeField source="expiredAt" label="만료일" />
        <WrapperField label="생성/수정">
          <DateTimeField source="createdAt" />
          <br />
          <DateTimeField source="updatedAt" />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
