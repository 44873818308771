import NotificationIcon from '@mui/icons-material/Notifications';
import { UserNotificationCreate } from './create';
import { UserNotificationList } from './list';
import { UserNotificationShow } from './show';

const userNotifications = {
  name: 'user-notifications',
  icon: NotificationIcon,
  list: UserNotificationList,
  show: UserNotificationShow,
  create: UserNotificationCreate,
  options: { label: '유저-알림 관리', permission: 'admin' },
};

export default userNotifications;
